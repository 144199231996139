import Loader from 'components/common/Loader'
import useNavigateBack from 'hooks/useNavigateBack'
import { useShowtime } from 'queries/showtimes'
import { Navigate, useParams } from 'react-router-dom'

type RouteParams = {
  showtimeId: string
}

// Retrocompatibility when the old URL format, redirecting to the new one for now
export default function ShowtimePage() {
  const { showtimeId } = useParams<RouteParams>()
  const { data: movieWithShowtimes, isError } = useShowtime(showtimeId)
  const { movie } = movieWithShowtimes || {}
  const navigateBack = useNavigateBack()

  if (!movie) {
    return <Loader />
  }
  if (isError) {
    navigateBack()
  }

  return <Navigate to={`${movie.urlPath}/seance/${showtimeId}`} replace />
}
