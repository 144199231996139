import { Grid, Stack, Text, UnstyledButton } from '@mantine/core'
import React, { useEffect, useRef, useState } from 'react'
import { Adjustments, CalendarTime, Location, Search } from 'tabler-icons-react'

import useFiltersCount from 'hooks/useFiltersCount'
import useSearchStore from 'states/useSearchStore'

import { DEFAULT_ADDRESS, FAVORITE_ADDRESS } from 'utils/utils'
import DateTimeInput, { buildDateText } from './DateTimeInput'
import LocationInput from './LocationInput'
import MobileButton from './MobileButton'
import RangeSelect from './RangeSelect'
import SearchInput from './SearchInput'

const BUTTON_SIZE = '32px'

type Props = {
  onToggleModals: (value: boolean) => void
  OtherOptionsState: { opened: boolean; toggle: () => void }
}

function MobileSearchBar({ onToggleModals, OtherOptionsState }: Props) {
  const { opened: openedOther, toggle: toggleOther } = OtherOptionsState
  const dateTimeParams = useSearchStore((state) => state.dateTimeParams)
  const place = useSearchStore((state) => state.place)
  const initialPlace = useRef(place)
  const dateText = buildDateText(dateTimeParams, 'Date')
  const [openedLocation, setOpenedLocation] = useState(false)
  const [openedDateTime, setOpenedDateTime] = useState(false)
  const [openedMovieInput, setOpenedMovieInput] = useState(false)
  const filtersCount = useFiltersCount()

  useEffect(() => {
    onToggleModals(
      openedLocation || openedDateTime || openedMovieInput || openedOther
    )
  }, [
    openedLocation,
    openedDateTime,
    openedMovieInput,
    openedOther,
    onToggleModals,
  ])

  // Close the location modal if the place has changed
  useEffect(() => {
    if (place !== initialPlace.current) {
      setOpenedLocation(false)
    }
    initialPlace.current = place
  }, [place])

  return (
    <div className="bg-zinc-900 h-[75px]">
      <Grid
        classNames={{
          root: 'px-2 !h-full h-[75px] !my-auto',
          inner: '!h-full !my-auto',
        }}
      >
        <Grid.Col span={3}>
          <MobileButton
            width="100%"
            color="text-zinc-100"
            opened={openedLocation}
            setOpened={setOpenedLocation}
            icon={<Location size={BUTTON_SIZE} />}
            text={(() => {
              if (place.address === FAVORITE_ADDRESS) {
                return 'Favoris'
              }
              if (place.address !== DEFAULT_ADDRESS) {
                return place.address
              }
              return 'Lieu'
            })()}
          >
            <Grid>
              <Grid.Col span={8}>
                <LocationInput />
              </Grid.Col>
              <Grid.Col span={4} className="pt-4">
                <RangeSelect />
              </Grid.Col>
            </Grid>
          </MobileButton>
        </Grid.Col>
        <Grid.Col span={3}>
          <MobileButton
            width="100%"
            color="text-zinc-100"
            opened={openedDateTime}
            setOpened={setOpenedDateTime}
            icon={<CalendarTime size={BUTTON_SIZE} />}
            text={dateText ?? 'Date'}
          >
            <DateTimeInput withPopover={false} />
          </MobileButton>
        </Grid.Col>
        <Grid.Col span={3}>
          <MobileButton
            width="100%"
            color="text-zinc-100"
            opened={openedMovieInput}
            setOpened={setOpenedMovieInput}
            icon={<Search size={BUTTON_SIZE} />}
            text="Recherche"
          >
            <SearchInput
              opened={openedMovieInput}
              setOpened={setOpenedMovieInput}
            />
          </MobileButton>
        </Grid.Col>
        <Grid.Col span={3}>
          <UnstyledButton className="w-full h-full" onClick={toggleOther}>
            <Stack
              gap={2}
              justify="center"
              align="center"
              className="relative cursor-pointer w-full h-full z-10  text-zinc-100"
            >
              <Adjustments size={BUTTON_SIZE} />
              <Text lineClamp={1} size="sm" fw={500} className="select-none">
                Filtres {filtersCount > 0 && ` (${filtersCount})`}
              </Text>
            </Stack>
          </UnstyledButton>
        </Grid.Col>
      </Grid>
    </div>
  )
}

export default React.memo(MobileSearchBar)
