import { Accordion, Group, RangeSlider, Text, Title } from '@mantine/core'

import { useEffect, useState } from 'react'
import useSearchStore from 'states/useSearchStore'
import { formatDuration } from 'utils/utils'

const MIN_DURATION = 0
const MAX_DURATION = 4 * 60
const MARKS = [
  { value: 0, label: '0' },
  { value: 60, label: formatDuration(60, true) },
  { value: 120, label: formatDuration(120, true) },
  { value: 180, label: formatDuration(180, true) },
  { value: MAX_DURATION, label: `${formatDuration(MAX_DURATION, true)}+` },
]

function getDurationFilterText(
  minDurationValue: number,
  maxDurationValue: number
) {
  if (minDurationValue === MIN_DURATION && maxDurationValue === MAX_DURATION) {
    return null
  }

  const minDurationTxt = formatDuration(minDurationValue, true)
  const maxDurationTxt = formatDuration(maxDurationValue, true)

  if (minDurationValue === MIN_DURATION) {
    return `< ${maxDurationTxt}`
  }

  if (maxDurationValue === MAX_DURATION) {
    return `> ${minDurationTxt}`
  }

  return `de ${minDurationTxt} à ${maxDurationTxt}`
}

function getValueWithDefault(value: number, default_value: number) {
  return value === default_value ? undefined : value
}

export default function DurationSelect() {
  const minDuration = useSearchStore(
    (state) => state.searchParams.minDuration ?? MIN_DURATION
  )
  const maxDuration = useSearchStore(
    (state) => state.searchParams.maxDuration ?? MAX_DURATION
  )
  const updateDurationParams = useSearchStore(
    (state) => state.updateDurationParams
  )
  const [values, setValues] = useState<[number, number]>(() => [
    minDuration,
    maxDuration,
  ])

  const [minDurationValue, maxDurationValue] = values
  const filterText = getDurationFilterText(minDurationValue, maxDurationValue)

  useEffect(() => {
    setValues([minDuration, maxDuration])
  }, [minDuration, maxDuration])

  return (
    <Accordion.Item value="duration">
      <Accordion.Control>
        <Group gap={6}>
          <Title order={5}>Durée</Title>
          {filterText && (
            <Text className="font-base text-gray-500">({filterText})</Text>
          )}
        </Group>
      </Accordion.Control>
      <Accordion.Panel>
        <RangeSlider
          size="lg"
          color="zinc.6"
          className="mb-4"
          minRange={10}
          step={10}
          min={MIN_DURATION}
          max={MAX_DURATION}
          value={values}
          onChange={setValues}
          onChangeEnd={([newMinDuration, newMaxDuration]) =>
            updateDurationParams({
              minDuration: getValueWithDefault(newMinDuration, MIN_DURATION),
              maxDuration: getValueWithDefault(newMaxDuration, MAX_DURATION),
            })
          }
          marks={MARKS}
          label={null}
        />
      </Accordion.Panel>
    </Accordion.Item>
  )
}
