import { AppShell, Group, Text } from '@mantine/core'
import { useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'

import AndroidBadge from 'components/common/AndroidBadge'
import AppleBadge from 'components/common/AppleBadge'
import Footer from 'components/common/Footer'
import LogoFull from 'components/common/LogoFull'
import HomeSearchBar from 'components/search/searchbar/HomeSearchBar'
import useSearchStore from 'states/useSearchStore'

function Home() {
  const navigate = useNavigate()
  const searchParams = useSearchStore((state) => state.searchParams)

  const initialParams = useRef(searchParams)
  useEffect(() => {
    // Automatically redirect to search page when the user chooses some parameters
    if (
      JSON.stringify(searchParams) !== JSON.stringify(initialParams.current)
    ) {
      navigate('/search', { state: { hasPrev: true } })
    }
  }, [searchParams, navigate])

  return (
    <AppShell
      className="fixed w-screen h-screen overflow-hidden"
      style={{
        backgroundSize: 'cover',
        backgroundPosition: '75% center',
        backgroundImage:
          'linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(home.jpg)',
      }}
    >
      <LogoFull />
      <Text
        className="text-white text-[35px] fixed top-[30%] left-[15%] right-[20%] font-bold"
        style={{
          fontFamily: 'Josefin Sans, sans-serif',
        }}
      >
        Les meilleurs films autour de vous
      </Text>
      <HomeSearchBar />
      <Group
        className="fixed w-full bottom-12 left-0 px-2"
        justify="center"
        gap="xs"
      >
        <AppleBadge scale={1.22} />
        <AndroidBadge scale={1.36} />
      </Group>
      <Footer color="#dddddd" />
    </AppShell>
  )
}

export default Home
