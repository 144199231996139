import { Group } from '@mantine/core'
import React, { useEffect } from 'react'
import { ArrowLeft, Share } from 'tabler-icons-react'

import ShareButton from 'components/common/ShareButton'
import useNavigateBack from 'hooks/useNavigateBack'
import { HiOutlineHome } from 'react-icons/hi'
import { useNavigate } from 'react-router-dom'

type Props = {
  children?: React.ReactNode | null
  className?: string
}

function MobileModalHeader({ children = null, className = '' }: Props) {
  const navigate = useNavigate()
  const navigateBack = useNavigateBack()

  // Add keyboard shortcuts
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        navigate('/search')
      } else if (event.key === 'Backspace') {
        navigateBack()
      }
    }
    window.addEventListener('keydown', handleKeyDown)
    return () => window.removeEventListener('keydown', handleKeyDown)
  }, [navigate, navigateBack])

  return (
    <Group justify="space-between" className={className}>
      <Group>
        <ArrowLeft
          size={30}
          className="cursor-pointer"
          onClick={navigateBack}
        />
        <HiOutlineHome
          size={30}
          className="cursor-pointer"
          onClick={() => navigate('/search')}
        />
      </Group>
      <Group>
        {children}
        <ShareButton>
          <Share size={24} className="px-0" />
        </ShareButton>
      </Group>
    </Group>
  )
}

export default React.memo(MobileModalHeader)
