import { List, Text } from '@mantine/core'
import TheaterAnchor from 'components/theater/TheaterAnchor'
import { DEFAULT_LOCATIONS } from 'hooks/useLocationService'
import LeafLet, { type LatLngTuple, type Map as LeafletMap } from 'leaflet'
import { useCurrentMovie } from 'providers/currentMovieProvider'
import React, { useMemo, useState } from 'react'
import { Circle, MapContainer, Marker, Popup, TileLayer } from 'react-leaflet'
import { Link } from 'react-router-dom'
import useDisplayStore from 'states/useDisplayStore'
import useSearchStore from 'states/useSearchStore'
import getDistanceInMeters from 'utils/distance'

function useShowtimesTheaterNearMe() {
  const { movie, matchingTheaters } = useCurrentMovie()
  const date = useDisplayStore((state) => state.currentDate)
  const { latitude: usrLat, longitude: usrLon } = useSearchStore(
    (state) => state.searchParams
  )
  const theatersAtDate = useMemo(
    () =>
      matchingTheaters.filter((t) =>
        t.showtimes.some(({ showtime }) => showtime.isSame(date, 'day'))
      ),
    [matchingTheaters, date]
  )

  // compute LatLng center and bounding box to show all items on the map (default)
  const theatersLatLng: LatLngTuple[] = theatersAtDate.map(
    ({ latitude, longitude }) => [latitude, longitude]
  )

  const theaterMarkers = (
    <>
      {theatersAtDate.map((theater) => {
        const { latitude: thLat, longitude: thLon } = theater
        const distance =
          usrLat && usrLon
            ? getDistanceInMeters(
                { latitude: thLat, longitude: thLon },
                { latitude: usrLat, longitude: usrLon }
              )
            : null

        const pos = LeafLet.latLng(thLat, thLon)

        return (
          <div key={theater.name}>
            <Marker
              position={pos}
              title={theater.name}
              icon={
                new LeafLet.Icon({
                  iconUrl: '/theater_location.png',
                  iconSize: [40, 40],
                })
              }
            >
              <Popup>
                <div className="flex h-full items-center w-fit">
                  <div className="align-middle max-w-[120px] text-ellipsis">
                    <Text lineClamp={2}>{theater.name}</Text>
                    <List size="xs">
                      {theater.showtimes
                        .filter((s) => s.showtime.isSame(date, 'date'))
                        .map((s) => (
                          <List.Item key={s.id}>
                            <Link
                              to={`${movie.urlPath}/seance/${s.id}`}
                              state={{ hasPrev: true }}
                            >
                              {s.showtime.format('HH:mm')}
                            </Link>
                          </List.Item>
                        ))}
                    </List>
                  </div>
                  <TheaterAnchor
                    theater={{
                      ...theater,
                      distance: distance ?? theater.distance,
                    }}
                  />
                </div>
              </Popup>
            </Marker>
          </div>
        )
      })}
    </>
  )
  return { theaterMarkers, theatersLatLng }
}

function useMyPosition() {
  const {
    latitude: usrLat,
    longitude: usrLon,
    range,
  } = useSearchStore((state) => state.searchParams)
  const hasUsr = usrLat != null && usrLon != null

  const usrPos = LeafLet.latLng(
    usrLat || DEFAULT_LOCATIONS.paris.latitude,
    usrLon || DEFAULT_LOCATIONS.paris.longitude
  )

  const positionMarker = (
    <>
      {range && (
        <Circle
          center={usrPos}
          radius={range * 1000}
          // transparent
          pathOptions={{ fillOpacity: 0 }}
        />
      )}
      {hasUsr && (
        <Marker
          draggable={false}
          position={usrPos}
          title="Ma position"
          icon={
            new LeafLet.Icon({
              iconUrl: '/location_marker.png',
              iconSize: [33, 50],
            })
          }
        >
          <Popup>
            <div className="flex h-full items-center w-fit">
              <div className="align-middle max-w-[120px] pr-4">
                <Text>Ma position</Text>
              </div>
            </div>
          </Popup>
        </Marker>
      )}
    </>
  )
  return { positionMarker, usrPos }
}

function ShowtimesNearMe() {
  const [map, setMap] = useState<LeafletMap | null>(null)
  const { positionMarker, usrPos } = useMyPosition()
  const { theaterMarkers, theatersLatLng } = useShowtimesTheaterNearMe()
  const bounds = LeafLet.latLngBounds(theatersLatLng)
  bounds.extend(usrPos)

  if (map != null) {
    // show all theaters in the map
    map.fitBounds(bounds)
  }

  return (
    <div
      className="w-hull h-full min-w-60 min-h-60"
      onTouchStart={(event) => event.stopPropagation()}
    >
      <MapContainer
        ref={setMap}
        className="w-full h-full"
        scrollWheelZoom={false}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {positionMarker}
        {theaterMarkers}
      </MapContainer>
    </div>
  )
}

export default React.memo(ShowtimesNearMe)
