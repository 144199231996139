import dayjs from 'dayjs'
import { omit, pick } from 'lodash'
import { useInfiniteQuery, useQuery, useQueryClient } from 'react-query'

import ShowtimeContext from 'types/ShowtimeContext'
import {
  type MovieWithShowtimes,
  type SearchParams,
  apiToMovieWithShowtimes,
} from 'types/showtimes'
import { RANGES } from 'utils/utils'
import apiFetch from './common'
import { movieById } from './movies'

export const PAGE_SIZE = 12

async function showtimeReservationUrl(showtimeId: number) {
  const { url } = await apiFetch<{ url: string }>(
    `/api/showtimes/${showtimeId}/reservation`
  )()
  return url
}

export function useShowtimeReservationUrl(showtimeId: number, enabled = true) {
  return useQuery(
    ['reservation', showtimeId],
    () => showtimeReservationUrl(showtimeId),
    { enabled: enabled && !!showtimeId }
  )
}

async function searchShowtimes(
  searchParams: SearchParams,
  page: number,
  pageSize: number
): Promise<MovieWithShowtimes[]> {
  if (
    searchParams.theaterIds === undefined &&
    (searchParams.latitude === undefined ||
      searchParams.longitude === undefined)
  ) {
    throw new Error('Missing latitude, longitude or theaterIds')
  }

  const start = searchParams.start || dayjs().subtract(10, 'minutes')
  const end = searchParams.end || dayjs().add(7, 'days').startOf('day')
  const params: any = { ...searchParams, start, end, page, pageSize }
  params.langs?.forEach((lang: string) => {
    params[lang] = true
  })

  const data = await apiFetch<any>('/api/showtimes/search', params)()
  return data.map(apiToMovieWithShowtimes)
}

export function useSearchShowtimes(
  searchParams: SearchParams,
  pageSize: number = PAGE_SIZE
) {
  return useInfiniteQuery({
    queryKey: ['showtimes', searchParams],
    queryFn: ({ pageParam = 1 }) =>
      searchShowtimes(searchParams, pageParam, pageSize),
    getNextPageParam: (
      lastPage: MovieWithShowtimes[],
      allPages: MovieWithShowtimes[][]
    ) => (lastPage.length === pageSize ? allPages.length + 1 : undefined),
  })
}

async function movieShowtimes(
  movieId: string | number | undefined,
  searchParams: SearchParams,
  moviesCache: MovieWithShowtimes[] = []
): Promise<MovieWithShowtimes[]> {
  if (!movieId) {
    throw new Error('Missing movieId')
  }

  const movieInCache = moviesCache.find(
    (movie) => movie.movie.id.toString() === movieId.toString()
  )
  if (movieInCache) {
    return [movieInCache]
  }

  const data = await searchShowtimes({ ...searchParams, movieId }, 1, 1)

  if (data.length > 0) {
    return data
  }

  // Fetch directly the movie's info if there are no showtimes
  const movie = await movieById(movieId)
  return [{ movie, theaters: [] }]
}

export function useMovieShowtimes(
  movieId: string | number | undefined,
  searchParams: SearchParams
) {
  // Retrieve the search pages already fetched to avoid fetching movies that are already in the cache
  const queryClient = useQueryClient()
  const searchCache: { pages: MovieWithShowtimes[][] } | undefined =
    queryClient.getQueryData(['showtimes', searchParams])
  const moviesCache = searchCache?.pages?.flat() || []

  // Omit search parameter that are filtering the type of movie, as the movie is already selected
  const filteredSearchParams = omit(searchParams, [
    'releasedBefore',
    'releasedAfter',
    'forChildren',
    'minDuration',
    'maxDuration',
  ])
  return useQuery<MovieWithShowtimes[]>(
    ['movieShowtimes', movieId?.toString(), filteredSearchParams],
    () => movieShowtimes(movieId, filteredSearchParams, moviesCache),
    { enabled: !!movieId }
  )
}

export function useMovieAllShowtimes(
  movieId: string | number | undefined,
  searchParams: SearchParams
) {
  const relevantSearchParams = {
    ...pick(searchParams, ['latitude', 'longitude', 'range', 'theaterIds']),
    start: dayjs().startOf('day'),
  }
  return useQuery<MovieWithShowtimes[]>(
    ['movieAllTheaters', movieId?.toString(), relevantSearchParams],
    () => searchShowtimes({ ...relevantSearchParams, movieId }, 1, 1),
    { enabled: !!movieId }
  )
}

async function theaterShowtimes(
  theaterId: string | number | undefined,
  searchParams: SearchParams
): Promise<MovieWithShowtimes[]> {
  if (!theaterId) {
    throw new Error('Missing theaterId')
  }

  const pageSize = 10
  const allMovies: MovieWithShowtimes[] = []
  let currentPage = 1

  while (allMovies.length === pageSize * (currentPage - 1)) {
    // eslint-disable-next-line no-await-in-loop
    const data = await searchShowtimes(
      { ...searchParams, theaterIds: [Number(theaterId)] },
      currentPage,
      pageSize
    )
    allMovies.push(...data)
    currentPage += 1
  }
  return allMovies
}

export function useTheaterShowtimes(
  theaterId: string | number | undefined,
  searchParams: SearchParams = {}
) {
  const relevantSearchParams = pick(searchParams, [
    'start',
    'end',
    'langs',
    'audioLangs',
    'releasedBefore',
    'releasedAfter',
    'forChildren',
    'genres',
    'excludeGenres',
    'minDuration',
    'maxDuration',
  ])
  return useQuery<MovieWithShowtimes[]>(
    ['theaterShowtimes', theaterId?.toString(), relevantSearchParams],
    () => theaterShowtimes(theaterId, relevantSearchParams),
    { enabled: !!theaterId }
  )
}

export async function bestRange(
  latitude: number,
  longitude: number,
  movieId?: string
) {
  const params: any = { latitude, longitude }
  if (movieId) {
    params.movie_id = movieId
  }
  const { best_range: range } = await apiFetch<{ best_range: number | null }>(
    '/api/showtimes/best_range',
    params
  )()
  if (range === null) {
    return null
  }
  return RANGES.find((val) => val >= range)
}

async function getShowtime(
  showtimeId: string | number | undefined,
  allShowtimes?: MovieWithShowtimes[] | undefined,
  movieId?: string | number | undefined,
  theaterId?: string | number | undefined
): Promise<ShowtimeContext> {
  if (!showtimeId) {
    throw new Error('Missing showtimeId')
  }

  // Retrieve the showtime from the theater data already fetched
  if (allShowtimes) {
    // eslint-disable-next-line no-restricted-syntax
    for (const { movie, theaters } of allShowtimes) {
      // eslint-disable-next-line no-restricted-syntax
      for (const theater of theaters) {
        const showtime = theater.showtimes.find(
          ({ id }) => id.toString() === showtimeId.toString()
        )
        if (showtime) {
          return new ShowtimeContext(showtime, movie, theater)
        }
      }
    }
  }

  const data = await apiFetch<any>(`/api/showtimes/${showtimeId}`)()
  const movie = apiToMovieWithShowtimes(data)
  const theater = movie.theaters[0]
  if (movieId && movie.movie.id.toString() !== movieId.toString()) {
    throw new Error('Movie and showtime do not match')
  }
  if (theaterId && theater.id.toString() !== theaterId.toString()) {
    throw new Error('Theater and showtime do not match')
  }
  const showtime = theater.showtimes[0]
  return new ShowtimeContext(showtime, movie.movie, theater)
}

export function useShowtime(
  showtimeId: string | number | undefined,
  allShowtimes?: MovieWithShowtimes[] | undefined,
  movieId?: string | number | undefined,
  theaterId?: string | number | undefined
) {
  return useQuery(
    ['showtime', showtimeId?.toString()],
    () => getShowtime(showtimeId, allShowtimes, movieId, theaterId),
    {
      enabled: !!showtimeId,
    }
  )
}
