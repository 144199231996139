import { Group, Switch } from '@mantine/core'
import React from 'react'

import InfoBubble from 'components/common/InfoBubble'
import trackEvent from 'queries/event'
import useSearchStore from 'states/useSearchStore'

function PriceToggle() {
  const showPrices = useSearchStore((state) => state.showPrices)
  const setShowPrices = useSearchStore((state) => state.setShowPrices)

  return (
    <Group gap={8} className="mx-auto mt-2">
      <Switch
        color="zinc.6"
        labelPosition="left"
        size="md"
        label="Afficher les prix"
        checked={showPrices}
        onChange={(event) => {
          trackEvent('toggle_prices', {
            showPrices: event.currentTarget.checked,
          })
          setShowPrices(event.currentTarget.checked)
        }}
      />
      <InfoBubble
        className="text-zinc-600"
        dropdownClassName="italic text-sm bg-zinc"
        bubbleText="Prix indicatif pour une place plein tarif. Ne tient pas compte d'éventuels suppléments, réductions ou séances spéciales, ou de modifications de la grille tarifaire."
      />
    </Group>
  )
}

export default React.memo(PriceToggle)
