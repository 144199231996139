import { Box, Text } from '@mantine/core'
import { memo } from 'react'
import { formatAudio, formatSubtitles } from 'utils/showtimes'

function ShowtimeLanguageBox({
  vo,
  audio,
  subtitles,
}: {
  vo: boolean
  audio: string | null
  subtitles: string[]
}) {
  return (
    <Box className="text-center">
      <Text className="font-semibold block">
        {formatAudio(vo, audio, true)}
      </Text>
      {subtitles.length > 0 ? (
        <Text className="italic block">{formatSubtitles(subtitles, true)}</Text>
      ) : null}
    </Box>
  )
}

export default memo(ShowtimeLanguageBox)
