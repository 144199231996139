import { Affix, Notification } from '@mantine/core'
import trackEvent from 'queries/event'
import { useEffect, useState } from 'react'
import { FaHeartBroken, FaRegHeart } from 'react-icons/fa'
import useSearchStore from 'states/useSearchStore'

function EmptyFavoriteNotification() {
  const emptyFavoriteError = useSearchStore((state) => state.emptyFavoriteError)
  const [notification, setNotification] = useState(false)
  const unapplySearchFavoriteTheaters = useSearchStore(
    (state) => state.unapplySearchFavoriteTheaters
  )

  useEffect(() => {
    if (emptyFavoriteError) {
      setNotification(true)
      unapplySearchFavoriteTheaters()
      trackEvent('empty_favorite_notification', {})
      setTimeout(() => setNotification(false), 5000)
    }
  }, [emptyFavoriteError, unapplySearchFavoriteTheaters, setNotification])

  if (!notification) {
    return null
  }

  return (
    <Affix position={{ top: '6rem', right: '5%' }} className="max-w-[90%]">
      <Notification
        icon={<FaHeartBroken size="1rem" />}
        color="red"
        title="Aucun cinéma en favoris !"
        onClose={() => setNotification(false)}
        withBorder
      >
        Cliquez sur <FaRegHeart className="inline mb-1" /> à côté du nom
        d&apos;un cinéma pour l&apos;ajouter à vos favoris.
      </Notification>
    </Affix>
  )
}

export default EmptyFavoriteNotification
