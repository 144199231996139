import { useLocation, useNavigate } from 'react-router-dom'

export default function useNavigateBack(defaultPath = '/search') {
  const navigate = useNavigate()
  const location = useLocation()
  const hasPrev: boolean | undefined = location.state?.hasPrev
  const navigateBack = () => {
    if (hasPrev) {
      navigate(-1)
    } else {
      navigate(defaultPath, { state: { hasPrev: true } })
    }
  }

  return navigateBack
}
