import { Modal, Stack } from '@mantine/core'
import { memo, useCallback } from 'react'
import { useLocation } from 'react-router-dom'

import useNavigateBack from 'hooks/useNavigateBack'
import { useCurrentShowtimes } from 'providers/currentShowtimesProvider'
import ShowtimeCard from './ShowtimeCard'
import ShowtimeEventBanner from './ShowtimeEventBanner'
import ShowtimeLanguageBox from './ShowtimeLanguageBox'
import ShowtimeLinks from './ShowtimeLinks'
import ShowtimeModalTitle from './ShowtimeModalTitle'

function ShowtimeModal() {
  const location = useLocation()
  const { showtimeContext } = useCurrentShowtimes()
  const navigateBack = useNavigateBack(
    `${location.pathname.split('/').slice(0, 3).join('/')}/seances`
  )
  const stopEvent = useCallback((event: any) => event.stopPropagation(), [])

  if (!showtimeContext) {
    return null
  }

  const { movie, showtime, isAvantPremiere, shareTitle } = showtimeContext
  const { vo, audio, subtitles, eventInfo } = showtime

  return (
    <Modal
      opened
      onClose={navigateBack}
      closeOnEscape={false}
      onKeyDown={(event) => {
        stopEvent(event)
        if (event.key === 'Escape' || event.key === 'Backspace') {
          navigateBack()
        }
      }}
      onTouchStart={stopEvent}
      onTouchMove={stopEvent}
      onTouchEnd={stopEvent}
      centered
      overlayProps={{
        backgroundOpacity: 0.55,
        blur: 3,
      }}
      classNames={{
        root: 'rounded-lg',
        body: 'bg-zinc-100',
        header: 'bg-zinc-100',
      }}
      title={<ShowtimeModalTitle movie={movie} />}
    >
      <Stack justify="center">
        <ShowtimeEventBanner
          eventInfo={eventInfo}
          isAvantPremiere={isAvantPremiere}
        />
        <ShowtimeCard showtimeContext={showtimeContext} />
        <ShowtimeLanguageBox vo={vo} audio={audio} subtitles={subtitles} />
        <ShowtimeLinks
          showtimeContext={showtimeContext}
          shareTitle={shareTitle}
          movieUrl={movie.urlPath}
        />
      </Stack>
    </Modal>
  )
}

export default memo(ShowtimeModal)
