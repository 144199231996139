import { Slider } from '@mantine/core'
import React, { memo, useEffect } from 'react'

import useSearchStore from 'states/useSearchStore'
import { RANGES } from 'utils/utils'

type Props = {
  className?: string
}

function RangeSlider({ className = '' }: Props) {
  const range = useSearchStore((state) => state.searchParams.range)
  const updateSearchParams = useSearchStore((state) => state.updateSearchParams)
  const [rangeIdx, setRangeIdx] = React.useState(4)

  useEffect(() => {
    if (range !== undefined) {
      setRangeIdx(RANGES.indexOf(range))
    }
  }, [range])

  return (
    <Slider
      onClick={(e) => e.stopPropagation()}
      size="lg"
      color="zinc.6"
      className={`mx-5 mt-7 mb-4 ${className}`}
      defaultValue={range}
      max={RANGES.length - 1}
      marks={[
        { value: 0, label: '1 km' },
        { value: RANGES.length - 1, label: '100 km' },
      ]}
      label={(idx) => `${RANGES[idx]} km`}
      labelAlwaysOn
      value={rangeIdx}
      onChange={setRangeIdx}
      onChangeEnd={(idx) => updateSearchParams({ range: RANGES[idx] })}
      styles={{ markWrapper: { width: 50 } }}
    />
  )
}

export default memo(RangeSlider)
