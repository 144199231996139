import { ActionIcon, Tooltip } from '@mantine/core'
import React from 'react'
import { Adjustments } from 'tabler-icons-react'

function OtherOptionsButton({ toggle }: { toggle: () => void }) {
  return (
    <Tooltip label="Autres filtres" position="bottom">
      <ActionIcon
        onClick={toggle}
        size="lg"
        variant="transparent"
        color="zinc.7"
      >
        <Adjustments size="32" />
      </ActionIcon>
    </Tooltip>
  )
}

export default React.memo(OtherOptionsButton)
