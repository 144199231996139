import Loader from 'components/common/Loader'
import dayjs from 'dayjs'
import useNavigateBack from 'hooks/useNavigateBack'
import {
  useMovieAllShowtimes,
  useMovieShowtimes,
  useShowtime,
  useTheaterShowtimes,
} from 'queries/showtimes'
import { type ReactNode, createContext, useEffect, useMemo } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import useSearchStore from 'states/useSearchStore'
import type ShowtimeContext from 'types/ShowtimeContext'
import type { MovieWithShowtimes } from 'types/showtimes'
import useValidContext from './common'

type CurrentShowtimesContextType = {
  matchingShowtimes: MovieWithShowtimes[]
  allShowtimes?: MovieWithShowtimes[]
  showtimeContext?: ShowtimeContext
}

export const CurrentShowtimesContext =
  createContext<CurrentShowtimesContextType | null>(null)

export const useCurrentShowtimes = () =>
  useValidContext(CurrentShowtimesContext)

type RouteParams = {
  movieId?: string
  movieUrlTitle: string
  showtimeId?: string
  theaterId?: string
}

type CurrentShowtimesProviderProps = {
  children: ReactNode | ReactNode[]
}

export function CurrentShowtimesProvider({
  children,
}: CurrentShowtimesProviderProps) {
  const { movieId, movieUrlTitle, showtimeId, theaterId } =
    useParams<RouteParams>()
  const location = useLocation()
  const navigate = useNavigate()
  const navigateBack = useNavigateBack()
  const searchParams = useSearchStore((state) => state.searchParams)
  const updateMovieId = useSearchStore((state) => state.updateMovieId)

  const { data: matchingMovieShowtimes, isError: matchingMovieIsError } =
    useMovieShowtimes(movieId, searchParams)
  const { data: allMovieShowtimes, isError: allMovieIsError } =
    useMovieAllShowtimes(movieId, searchParams)
  const { data: matchingTheaterShowtimes, isError: matchingTheaterIsError } =
    useTheaterShowtimes(theaterId, searchParams)
  const { data: allTheaterShowtimes, isError: allTheaterIsError } =
    useTheaterShowtimes(theaterId, { start: dayjs().startOf('day') })
  const { data: showtimeContext, isError: showtimeIsError } = useShowtime(
    showtimeId,
    allMovieShowtimes || allTheaterShowtimes,
    movieId,
    theaterId
  )

  const contextValue = useMemo(
    () => ({
      matchingShowtimes:
        matchingMovieShowtimes || matchingTheaterShowtimes || [],
      allShowtimes: allMovieShowtimes || allTheaterShowtimes,
      showtimeContext,
    }),
    [
      matchingMovieShowtimes,
      matchingTheaterShowtimes,
      allMovieShowtimes,
      allTheaterShowtimes,
      showtimeContext,
    ]
  )

  useEffect(() => {
    if (
      matchingMovieIsError ||
      allMovieIsError ||
      matchingTheaterIsError ||
      allTheaterIsError ||
      showtimeIsError
    ) {
      navigateBack()
    }
  }, [
    matchingMovieIsError,
    allMovieIsError,
    matchingTheaterIsError,
    allTheaterIsError,
    showtimeIsError,
    navigateBack,
  ])

  useEffect(() => {
    const movie = matchingMovieShowtimes?.[0]?.movie
    if (movie && movieUrlTitle && movie.urlTitle !== movieUrlTitle) {
      // Clean the URL when the movie's slug has changed
      const newPath = location.pathname.replace(
        `/${movieUrlTitle}-`,
        `/${movie.urlTitle}-`
      )
      navigate(newPath, { replace: true })
    }
  }, [matchingMovieShowtimes, movieUrlTitle])

  useEffect(() => {
    // keep movieId for search range computation
    updateMovieId(movieId?.toString())
    return () => updateMovieId(undefined)
  }, [movieId, updateMovieId])

  // Displays a loading spinner until the first data is fetched
  if (
    matchingMovieShowtimes === undefined &&
    matchingTheaterShowtimes === undefined
  ) {
    return <Loader />
  }

  return (
    <CurrentShowtimesContext.Provider value={contextValue}>
      {children}
    </CurrentShowtimesContext.Provider>
  )
}
