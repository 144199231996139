import { useEffect } from 'react'

import { Affix, CloseButton, Flex, Text } from '@mantine/core'
import { useDisclosure, useElementSize } from '@mantine/hooks'

export default function BottomDrawer({
  headerText,
  children,
  onClose = null,
}: {
  headerText: string
  children: React.ReactNode | React.ReactNode[]
  onClose?: (() => void) | null
}) {
  const { ref, height } = useElementSize()

  const [opened, { close }] = useDisclosure(true)
  // set height as css variable (and set it to 0 when closed)
  useEffect(() => {
    if (opened) {
      document.documentElement.style.setProperty(
        '--banner-height',
        `${height}px`
      )
    }
    return () => {
      document.documentElement.style.setProperty('--banner-height', '0px')
    }
  }, [height, opened])

  return opened ? (
    <Affix
      ref={ref}
      position={{ bottom: '0px', left: '0px' }}
      className="bg-zinc-900 text-white text-center h-fit w-full"
      style={{
        fontFamily: 'Josefin Sans, sans-serif',
      }}
      zIndex={100}
    >
      <Flex direction="column" className="grow my-1">
        <div className="grow text-center align-middle">
          <Text size="sm">{headerText}</Text>
        </div>
        <div className="my-1 w-full">{children}</div>
      </Flex>
      <CloseButton
        className="text-zinc-200 absolute right-0 top-0 m-1"
        onClick={() => {
          if (onClose) {
            onClose()
          }
          close()
        }}
      />
    </Affix>
  ) : null
}
