import { Text } from '@mantine/core'
import dayjs from 'dayjs'
import React, { useCallback } from 'react'
import { Link } from 'react-router-dom'
import {
  BrandFacebook,
  BrandInstagram,
  BrandLinkedin,
  BrandTwitter,
} from 'tabler-icons-react'

import trackEvent from 'queries/event'

type Props = {
  color: string
  relative?: boolean
}

function Footer({ color, relative = false }: Props) {
  let className = 'w-full text-center'
  if (relative) {
    className += ' bottom-0 left-0 py-2'
  } else {
    className += ' fixed bottom-0 left-0 py-2'
  }

  const trackClick = useCallback((website: string) => {
    trackEvent('social_click', { website })
  }, [])

  return (
    <div className={className}>
      <Text color={color} style={{ fontSize: '12px' }}>
        © {dayjs().year()} La Bobine •{' '}
        <Link to="/privacy">Confidentialité</Link> •{' '}
        <a href="mailto:contact@bobine.art" onClick={() => trackClick('email')}>
          <b>Nous contacter</b>
        </a>{' '}
        <br />
        <a
          aria-label="Instagram"
          href="https://www.instagram.com/bobine.art"
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => trackClick('instagram')}
        >
          <BrandInstagram
            className="inline"
            size={22}
            strokeWidth={2}
            color={color}
          />
        </a>
        <a
          aria-label="Linkedin"
          href="https://www.linkedin.com/company/86201500"
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => trackClick('linkedin')}
        >
          <BrandLinkedin
            className="inline"
            size={22}
            strokeWidth={2}
            color={color}
          />
        </a>
        <a
          aria-label="Facebook"
          href="https://www.facebook.com/people/La-Bobine/100086554010065/"
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => trackClick('facebook')}
        >
          <BrandFacebook
            className="inline"
            size={20}
            strokeWidth={2}
            color={color}
          />
        </a>
        <a
          aria-label="Twitter"
          href="https://twitter.com/bobine_art"
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => trackClick('twitter')}
        >
          <BrandTwitter
            className="inline"
            size={20}
            strokeWidth={2}
            color={color}
          />
        </a>
      </Text>
    </div>
  )
}

export default React.memo(Footer)
