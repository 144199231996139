import { Checkbox, Stack, Title } from '@mantine/core'
import useSearchStore from 'states/useSearchStore'

export default function VersionSelect() {
  const langs = useSearchStore((state) => state.searchParams.langs)
  const updateSearchParams = useSearchStore((state) => state.updateSearchParams)
  return (
    <Stack>
      <Title order={5}>Version</Title>
      {['vo', 'vf'].map((lang) => (
        <Checkbox
          key={lang}
          label={lang.toUpperCase()}
          checked={langs?.includes(lang) || false}
          onChange={() => {
            const oldLangs = langs === undefined ? [] : langs
            let newLangs: string[] | undefined = oldLangs.includes(lang)
              ? oldLangs.filter((l) => l !== lang)
              : oldLangs.concat(lang)
            if (newLangs.length === 0) {
              newLangs = undefined
            }
            updateSearchParams({ langs: newLangs })
          }}
          color="zinc.6"
          size="md"
        />
      ))}
    </Stack>
  )
}
