import { useViewportSize } from '@mantine/hooks'
import { type ReactNode, createContext, useMemo } from 'react'
import useValidContext from './common'

const MOBILE_MAX_WIDTH = 900
const BROWSER_RE = /safari|chrome|firefox/
const IOS_RE = /iphone|ipod|ipad|macintosh/
const IPHONE_RE = /iphone/

type IsMobileContextType = {
  isMobile: boolean
  isMobileApp: boolean
  isIos: boolean
  hasBottomBar: boolean
}

const isMobileContext = createContext<IsMobileContextType | null>(null)

export const useIsMobile = () => useValidContext(isMobileContext)

export function IsMobileProvider({
  children,
}: {
  children: ReactNode | ReactNode[]
}) {
  // useViewportSize will subscribe to "resize" and "orientationchange" events.
  const { width: viewportWidth } = useViewportSize()
  const isMobile = viewportWidth < MOBILE_MAX_WIDTH

  const userAgent = window.navigator.userAgent.toLowerCase()
  const iosBrowser = BROWSER_RE.test(userAgent)
  const isIos = IOS_RE.test(userAgent)

  const isMobileApp = (isIos && !iosBrowser) || userAgent.includes('wv')

  // iPhones have a bottom bar on all models since iPhone X, except for SE models
  const screenHeight = window.screen.height * (window.devicePixelRatio || 1)
  const hasBottomBar =
    isMobile &&
    isMobileApp &&
    IPHONE_RE.test(userAgent) &&
    (screenHeight > 1920 || screenHeight === 1792)

  const value = useMemo(
    () => ({
      isMobile,
      isMobileApp,
      isIos,
      hasBottomBar,
    }),
    [isMobile, isMobileApp, isIos, hasBottomBar]
  )

  return (
    <isMobileContext.Provider value={value}>
      {children}
    </isMobileContext.Provider>
  )
}
