import dayjs from 'dayjs'
import type ShowtimeContext from 'types/ShowtimeContext'
import { toLocalTime } from './utils'

const icsDatetimeFormat = (date: dayjs.Dayjs) =>
  date.utc().format('YYYYMMDDTHHmmss[Z]')

const PRE_SHOWTIME_DURATION = 20

type CalendarEvent = {
  id: number
  title: string
  startDate: dayjs.Dayjs
  endDate: dayjs.Dayjs
  location: string
  description: string
  latitude: number
  longitude: number
}

export const contextToEventData = ({
  showtime,
  movie,
  theater,
}: ShowtimeContext): CalendarEvent => {
  const startDate = toLocalTime(showtime.showtime)
  // Round up to the nearest 5 minutes
  const showtimeDuration =
    Math.ceil((movie.duration + PRE_SHOWTIME_DURATION) / 5) * 5
  const endDate = startDate.add(showtimeDuration, 'minute')

  return {
    id: showtime.id,
    title: movie.titleVf,
    startDate,
    endDate,
    location: `${theater.name}, ${theater.address}`,
    description: window.location.href,
    latitude: theater.latitude,
    longitude: theater.longitude,
  }
}

export const generateIcsEvent = (eventData: CalendarEvent) => {
  const event = [
    'BEGIN:VCALENDAR',
    'VERSION:2.0',
    'CALSCALE:GREGORIAN',
    'PRODID:Bobine',
    'METHOD:PUBLISH',
    'BEGIN:VEVENT',
    `UID:${eventData.id}@bobine.art`,
    `SUMMARY:${eventData.title}`,
    `DTSTAMP:${icsDatetimeFormat(dayjs())}`,
    `DTSTART:${icsDatetimeFormat(eventData.startDate)}`,
    `DTEND:${icsDatetimeFormat(eventData.endDate)}`,
    `LOCATION:${eventData.location}`,
    `GEO:${eventData.latitude};${eventData.longitude}`,
    `URL:${window.location.href}`,
    `DESCRIPTION:${eventData.description}`,
    'END:VEVENT',
    'END:VCALENDAR',
  ].join('\r\n')

  const blob = new Blob([event], { type: 'text/calendar' })
  return URL.createObjectURL(blob)
}
