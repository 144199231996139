import { Affix, Container, Drawer, Space, Text } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { useEffect, useRef, useState } from 'react'
import { Outlet, ScrollRestoration, useLocation } from 'react-router-dom'

import InstallOrUpgradeDrawer from 'components/common/InstallOrUpgradeDrawer'
import NotificationsPermissionModal from 'components/common/NotificationsPermissionModal'
import useAppReviewRequest from 'hooks/useAppReviewRequest'
import { useIsMobile } from 'providers/isMobileProvider'
import useLocationStore from 'states/useLocationStore'
import useSearchStore from 'states/useSearchStore'

import EmptyFavoriteNotification from './EmptyFavoriteNotification'
import Search from './Search'
import MobileSearchBar from './searchbar/MobileSearchBar'
import OtherOptions from './searchbar/OtherOptions'
import SearchBar from './searchbar/SearchBar'

export default function SearchLayout() {
  const [openedOther, { close: closeOther, toggle: toggleOther }] =
    useDisclosure(false)
  const searchParams = useSearchStore((state) => state.searchParams)
  const routeLocation = useLocation()
  const { isMobile } = useIsMobile()
  const [isSearchMounted, setIsSearchMounted] = useState(false)
  const [anyOpenedModal, setAnyOpenedModal] = useState(false)
  const headerHeight = isMobile ? 75 : 87
  const findLocation = useLocationStore((state) => state.findLocation)
  const place = useSearchStore((state) => state.place)
  const transitionCount = useRef(0)
  useAppReviewRequest(transitionCount)

  // Allow to mount the Search component only once we reached the page
  useEffect(() => {
    if (routeLocation.pathname.startsWith('/search')) {
      transitionCount.current += 1
      setIsSearchMounted(true)
    }
  }, [routeLocation.pathname])

  useEffect(() => {
    // Set search default location if not provided, using the URL's movie id to determine the best range
    if (searchParams.latitude === undefined) {
      findLocation(place)
    }
  }, [findLocation])

  useEffect(() => {
    document.documentElement.style.setProperty(
      '--header-height',
      `${headerHeight}px`
    )
    document.documentElement.style.setProperty(
      '--page-height',
      'calc(100dvh - var(--header-height, 0px) - var(--banner-height, 0px))'
    )
  }, [isMobile])

  return (
    <>
      <ScrollRestoration
        getKey={
          (location) => location.pathname.split('/').slice(0, 3).join('/') // Keep the two first sections of the current path
        }
      />
      <Drawer
        onKeyDown={(event) => {
          event.stopPropagation()
          if (event.key === 'Escape' || event.key === 'Backspace') {
            closeOther()
          }
        }}
        opened={openedOther}
        onClose={closeOther}
        position="right"
        size={isMobile ? '90%' : 'md'}
        title={
          <Text size="xl" className="font-semibold">
            Autres filtres
          </Text>
        }
      >
        <OtherOptions onClose={closeOther} />
      </Drawer>

      <EmptyFavoriteNotification />
      <Container
        className={`bg-zinc-50 min-h-[100dvh] ${
          anyOpenedModal ? 'pointer-events-none brightness-75' : ''
        }`}
        fluid
        px={0}
      >
        <Affix position={{ top: 0, left: 0 }} className="shadow-md w-full z-50">
          {isMobile ? (
            <MobileSearchBar
              onToggleModals={setAnyOpenedModal}
              OtherOptionsState={{ toggle: toggleOther, opened: openedOther }}
            />
          ) : (
            <SearchBar
              onToggleModals={setAnyOpenedModal}
              OtherOptionsState={{ toggle: toggleOther, opened: openedOther }}
            />
          )}
        </Affix>
        <Space h={headerHeight} />
        <div
          className={
            routeLocation.pathname.startsWith('/search') ? 'block' : 'hidden'
          }
        >
          {isSearchMounted && (
            <Search isVisible={routeLocation.pathname.startsWith('/search')} />
          )}
        </div>
        <Outlet />
      </Container>
      <InstallOrUpgradeDrawer />
      {transitionCount.current > 2 && <NotificationsPermissionModal />}
    </>
  )
}
