import { Stack, Text } from '@mantine/core'
import type dayjs from 'dayjs'
import React from 'react'
import { Space } from 'tabler-icons-react'
import { formatShowtimeDate } from 'utils/showtimes'

type Props = {
  showtimeDate: dayjs.Dayjs
  theaterName: string
}

function ShowtimeText({ showtimeDate, theaterName }: Props) {
  const showtimeStr = formatShowtimeDate(showtimeDate)
  return (
    <Stack gap={0.5}>
      <Text size="sm" className="truncate pr-1 font-normal">
        {theaterName}
      </Text>
      <Text size="sm" className="font-semibold capitalize">
        {showtimeStr}
      </Text>
      <Space size={0.5} />
    </Stack>
  )
}

export default React.memo(ShowtimeText)
