import { Group, Space, Stack, Text } from '@mantine/core'
import dayjs from 'dayjs'
import { memo } from 'react'

import type { Movie } from 'types/showtimes'
import { formatDuration } from 'utils/utils'

type Props = {
  movie: Movie
  titleLineClamp?: number
}

function MovieDetails({ movie, titleLineClamp = 1 }: Props) {
  return (
    <div className="group overflow-hidden">
      <Stack className="w-fit" gap={0}>
        <Text
          lineClamp={titleLineClamp}
          size="sm"
          c="zinc.8"
          fw={500}
          className=" uppercase ellipsis"
        >
          {movie.titleVf}
        </Text>
        <div className="border-b-2 border-zinc-200 w-2/3 group-hover:w-full transition-all" />
      </Stack>
      <Stack className="flex leading-6 truncate text-zinc-600" gap={3}>
        <Space h={1} />
        <Text size="sm" className="font-regular capitalize truncate">
          {movie.director !== null && movie.director}
          {null}
        </Text>
        <Group gap={1}>
          {movie.releaseDate !== null && (
            <Text size="sm">{dayjs(movie.releaseDate).format('YYYY')}</Text>
          )}
          {movie.releaseDate !== null && (
            <Text size="sm" className="!px-2">
              ·
            </Text>
          )}

          {movie.duration > 0 && (
            <Text size="sm">{formatDuration(movie.duration)}</Text>
          )}
        </Group>
      </Stack>
    </div>
  )
}

export default memo(MovieDetails)
