import { memo } from 'react'

import { Stack } from '@mantine/core'
import type { Movie } from 'types/showtimes'
import { formatDuration } from 'utils/utils'

function ShowtimeModalTitle({ movie }: { movie: Movie }) {
  return (
    <Stack gap="xs">
      <h2 className="text-2xl font-semibold"> {movie.titleVf}</h2>
      <p className="italic text-zinc-600">{formatDuration(movie.duration)}</p>
    </Stack>
  )
}

export default memo(ShowtimeModalTitle)
