import { useEffect, useMemo } from 'react'
import { Helmet } from 'react-helmet'
import {
  Navigate,
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom'

import Home from 'components/home/Home'
import Privacy from 'components/home/Privacy'
import MoviePage from 'components/search/moviemodal/MoviePage'

import SearchLayout from 'components/search/Layout'
import ShowtimePage from 'components/search/moviemodal/ShowtimePage'
import TheaterPage from 'components/theater/TheaterPage'
import useLocationService from 'hooks/useLocationService'
import { IsMobileProvider } from 'providers/isMobileProvider'
import useLocationStore from 'states/useLocationStore'

function App() {
  const { placePredictions, getPlacePredictions, findLocation } =
    useLocationService()
  const setLocationState = useLocationStore((state) => state.setLocationState)

  useEffect(() => {
    setLocationState({
      placePredictions,
      getPlacePredictions,
      findLocation,
    })
  }, [setLocationState, placePredictions, getPlacePredictions, findLocation])

  const title = 'Les meilleurs films autour de vous'
  const description =
    'La Bobine permet de trouver des séances de cinéma inattendues. On met en avant les films les mieux notés, les classiques comme les dernières sorties. Découvrez le programme des petites salles indépendantes de votre quartier !'

  const router = useMemo(
    () =>
      createBrowserRouter(
        createRoutesFromElements(
          <>
            <Route path="/" element={<Home />} />
            <Route element={<SearchLayout />}>
              <Route path="/search" element={null} />
              <Route
                path="/film/:movieUrlTitle-:movieId/:tab"
                element={<MoviePage />}
              />
              <Route
                path="/film/:movieUrlTitle-:movieId"
                element={<MoviePage />}
              />
              <Route
                path="/film/:movieUrlTitle-:movieId/seance/:showtimeId"
                element={<MoviePage />}
              />
              <Route
                path="seance/:movieUrlTitle-:showtimeId"
                element={<ShowtimePage />}
              />
              <Route path="seance/:showtimeId" element={<ShowtimePage />} />
              <Route
                path="/cinema/:theaterUrlTitle-:theaterId/:tab"
                element={<TheaterPage />}
              />
              <Route
                path="/cinema/:theaterUrlTitle-:theaterId"
                element={<TheaterPage />}
              />
              <Route
                path="/cinema/:theaterUrlTitle-:theaterId/seance/:showtimeId"
                element={<TheaterPage />}
              />
            </Route>
            <Route path="/privacy" element={<Privacy />} />
            <Route path="*" element={<Navigate to="/" replace />} />
          </>
        )
      ),
    []
  )

  return (
    <div className="App">
      <Helmet>
        <title>La Bobine - {title}</title>
        <meta name="description" content={description} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content="/preview.png" />
        <meta property="og:url" content={`${window.location.href}`} />
      </Helmet>
      <IsMobileProvider>
        <RouterProvider router={router} />
      </IsMobileProvider>
    </div>
  )
}

export default App
