import { Popover, Stack, Text } from '@mantine/core'
import { memo, useState } from 'react'

import useSearchStore from 'states/useSearchStore'
import { DEFAULT_DATE_TEXT, type DateTimeParams } from 'types/showtimes'
import DateSelect, { possibleDates } from './DateSelect'
import HourSelect, {
  UNDEFINED_START_HOUR,
  UNDEFINED_END_HOUR,
} from './HourSelect'

export const buildDateText = (
  { day }: DateTimeParams,
  defaultDate: string = DEFAULT_DATE_TEXT
) => {
  if (day === undefined) {
    return defaultDate
  }
  return possibleDates[Number.parseInt(day, 10) + 1].label
}

export const buildTimeText = (hours: [number, number], isNow: boolean) => {
  const [startHour, endHour] = hours
  const noStart = startHour === undefined || startHour === UNDEFINED_START_HOUR
  const noEnd = endHour === undefined || endHour === UNDEFINED_END_HOUR
  if ((noStart && noEnd) || isNow) {
    return undefined
  }
  if (noStart) {
    return `jusqu'à ${endHour}h`
  }
  if (noEnd) {
    return `à partir de ${startHour}h`
  }
  return `de ${startHour}h à ${endHour}h`
}

type Props = {
  withPopover?: boolean
}

function DateTimeInput({ withPopover = true }: Props) {
  const dateTimeParams = useSearchStore((state) => state.dateTimeParams)
  const hoursParams: [number, number] = [
    dateTimeParams.startHour || UNDEFINED_START_HOUR,
    dateTimeParams.endHour || UNDEFINED_END_HOUR,
  ]
  const [hours, setHours] = useState<[number, number]>(() => hoursParams)

  const isNow = dateTimeParams.day === '-1'
  const dateText = buildDateText(dateTimeParams)
  const timeText = buildTimeText(hours, isNow)

  const timeTextComponent = (
    <Text size="sm" className="truncate text-zinc-600">
      {timeText}
    </Text>
  )

  const forms = (
    <Stack>
      <DateSelect />
      <HourSelect hours={hours} setHours={setHours} isNow={isNow} />
    </Stack>
  )

  if (!withPopover) {
    return forms
  }

  return (
    <Popover trapFocus position="bottom" withArrow shadow="md">
      <Popover.Target>
        {timeText === undefined ? (
          <Text
            c={dateText === DEFAULT_DATE_TEXT ? 'zinc.5' : 'black'}
            className="w-full items-center ml-1 cursor-pointer"
          >
            {dateText}
          </Text>
        ) : (
          <Stack className="w-full h-12 select-none cursor-pointer" gap={1}>
            <Text className="truncate" size="md">
              {dateText}
            </Text>
            {timeTextComponent}
          </Stack>
        )}
      </Popover.Target>
      <Popover.Dropdown>{forms}</Popover.Dropdown>
    </Popover>
  )
}

export default memo(DateTimeInput)
