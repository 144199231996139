import { Container, Text } from '@mantine/core'
import { Link } from 'react-router-dom'

function Logo() {
  return (
    <Container fluid style={{ position: 'absolute', top: 0, left: 0 }} px={0}>
      <Link to="/">
        <img src="/logo_full_transparent.svg" alt="logo" />
        <Text className="text-white text-[50px] fixed top-[19px] left-[60px] font-[Changa,_sans-serif]">
          obine
        </Text>
      </Link>
    </Container>
  )
}

export default Logo
