import type { Place } from 'types/showtimes'
import { create } from 'zustand'
import { useShallow } from 'zustand/shallow'

type PlacePrediction = {
  place_id: string
  description: string
}

export interface ILocationUtils {
  findLocation: (place: Place, movieId?: string) => void
  placePredictions: PlacePrediction[]
  getPlacePredictions: (input: string) => void
}
type ILocationState = ILocationUtils & {
  setLocationState: (locationFunctions: ILocationUtils) => void
}

const locationStore = create<ILocationState>((set) => ({
  findLocation: () => {},
  placePredictions: [],
  getPlacePredictions: () => {},
  setLocationState: ({
    findLocation,
    placePredictions,
    getPlacePredictions,
  }: ILocationUtils) =>
    set({ findLocation, placePredictions, getPlacePredictions }),
}))

function useLocationStore<T>(selector: (state: ILocationState) => T): T {
  return locationStore(useShallow(selector))
}

export default useLocationStore
