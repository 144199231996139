import { Center, Image, Stack, Text } from '@mantine/core'
import React from 'react'

import useFiltersCount from 'hooks/useFiltersCount'
import OtherOptionsReset from './searchbar/OtherOptionsReset'

type Props = {
  hasResults: boolean
}

function NoMoreResult({ hasResults }: Props) {
  const filtersCount = useFiltersCount()

  return (
    <Stack className="text-center mb-4">
      <Center className="flex h-32 mt-2.5">
        <Image
          fit="contain"
          src="/popcorn.svg"
          alt="no result"
          w="65px"
          className="mr-6"
        />
        <Text>
          {hasResults
            ? "C'était la dernière séance..."
            : "Oh non ! Il semble qu'il n'y ait aucune séance correspondant à vos critères..."}
        </Text>
      </Center>
      {filtersCount > 0 && <OtherOptionsReset />}
    </Stack>
  )
}

export default React.memo(NoMoreResult)
