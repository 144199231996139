import { Button, Group, Image, Modal } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { useCallback, useState } from 'react'

import trackEvent from 'queries/event'
import useDisplayStore from 'states/useDisplayStore'
import { globalFunc, globalVar } from 'utils/utils'
import MessageModal from './MessageModal'

function NotificationsPermissionModal() {
  const {
    requestedNotificationsPermission,
    updateRequestedNotificationsPermission,
  } = useDisplayStore((state) => ({
    requestedNotificationsPermission: state.requestedNotificationsPermission,
    updateRequestedNotificationsPermission:
      state.updateRequestedNotificationsPermission,
  }))
  const [opened, { open, close }] = useDisclosure(true)
  const [askedAndRefused, setAskedAndRefused] = useState(false)

  const handleRefuse = useCallback(() => {
    trackEvent('permissions_refused', {})
    updateRequestedNotificationsPermission(true)
    close()
  }, [close])

  const handleAccept = useCallback(() => {
    trackEvent('permissions_asking', {})
    const callback = ({
      status,
      token,
    }: {
      status: string
      token: string | null
    }) => {
      window.notifPermission = status
      updateRequestedNotificationsPermission(true)
      if (status === 'granted' && token) {
        trackEvent('permissions_accepted', {})
        window.expoPushToken = token
      } else {
        setAskedAndRefused(true)
        open()
        trackEvent('permissions_refused_after_ask', {})
      }
    }
    globalFunc('askNotifPermission', callback)
    close()
  }, [close])

  if (!globalVar('notifPermission') || !globalVar('askNotifPermission')) {
    return null
  }

  if (askedAndRefused) {
    return (
      <MessageModal>
        Pour recevoir les notifications, activez les autorisations dans les
        paramètres de votre téléphone :
        <br /> Applications &#x3009; Bobine &#x3009; Notifications.
      </MessageModal>
    )
  }

  if (
    requestedNotificationsPermission ||
    globalVar('notifPermission') === 'granted'
  ) {
    // No need to ask again
    return null
  }

  return (
    <Modal
      opened={opened}
      onClose={close}
      withCloseButton={false}
      centered
      className="bg-neutral-900"
      classNames={{
        content: 'bg-neutral-900 text-white text-center',
      }}
      style={{
        fontFamily: 'Josefin Sans, sans-serif',
      }}
    >
      <div className="font-semibold text-xl mb-4">
        Les grands films ne devraient pas vous échapper.
      </div>
      <Image src="/notification.jpg" className="mb-4 max-h-[40vh]" />
      Activez les notifications pour découvrir les meilleures séances et ne
      manquez jamais un film qui pourrait vous plaire.
      <Group justify="space-between" className="mx-auto px-5 mt-8 w-full">
        <Button
          size="md"
          color="zinc"
          className="border-zinc-600 focus:outline-none"
          style={{
            fontFamily: 'Josefin Sans, sans-serif',
          }}
          onClick={handleRefuse}
        >
          Non merci
        </Button>
        <Button
          size="md"
          color="zinc.6"
          variant="white"
          className="border-white"
          style={{
            fontFamily: 'Josefin Sans, sans-serif',
          }}
          onClick={handleAccept}
        >
          Activer
        </Button>
      </Group>
    </Modal>
  )
}

export default NotificationsPermissionModal
