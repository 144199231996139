import type React from 'react'

import { RangeSlider } from '@mantine/core'
import { useIsMobile } from 'providers/isMobileProvider'
import useSearchStore from 'states/useSearchStore'

// in order to make rangeslider easier to use
// the hour choice is limited from 10h to 22h (the most relevant hours)
// to cover values outside of this range, the edge values, 9h and 23h, will be used
// they will be turned into undefined values in search parameters
const START_HOUR = 10
export const END_HOUR = 22
export const UNDEFINED_START_HOUR = START_HOUR - 1
export const UNDEFINED_END_HOUR = END_HOUR + 1

const TIME_MARKS = [
  { value: START_HOUR, label: `${START_HOUR}h` },
  { value: 14, label: '14h' },
  { value: 18, label: '18h' },
  { value: END_HOUR, label: `${END_HOUR}h` },
]

function HourSelect({
  hours,
  setHours,
  isNow,
}: {
  hours: [number, number]
  setHours: React.Dispatch<React.SetStateAction<[number, number]>>
  isNow: boolean
}) {
  const { isMobile } = useIsMobile()
  const updateDateTimeParams = useSearchStore(
    (state) => state.updateDateTimeParams
  )

  return (
    <RangeSlider
      className={isMobile ? 'h-12 mt-5' : 'h-10'}
      size="lg"
      color="zinc.6"
      minRange={1}
      step={1}
      min={UNDEFINED_START_HOUR}
      max={UNDEFINED_END_HOUR}
      value={hours}
      onChange={setHours}
      onChangeEnd={([newStartHour, newEndHour]) =>
        updateDateTimeParams({
          startHour:
            newStartHour === UNDEFINED_START_HOUR ? undefined : newStartHour,
          endHour: newEndHour === UNDEFINED_END_HOUR ? undefined : newEndHour,
        })
      }
      marks={TIME_MARKS}
      label={(value) =>
        !isMobile || [UNDEFINED_START_HOUR, UNDEFINED_END_HOUR].includes(value)
          ? null
          : `${value}h`
      }
      labelAlwaysOn={isMobile}
      disabled={isNow}
    />
  )
}

export default HourSelect
