import { Anchor, Group, Image, Stack } from '@mantine/core'
import { useCallback } from 'react'

import { useCurrentMovie } from 'providers/currentMovieProvider'
import trackEvent from 'queries/event'

type Props = {
  kind: 'allocine' | 'senscritique' | 'imdb' | 'mubi'
}

export default function MovieRating({ kind }: Props) {
  const { movie } = useCurrentMovie()
  const trackClick = useCallback(() => {
    trackEvent('rating_click', {
      id: movie.id,
      movieName: movie.titleVf,
      website: kind,
    })
  }, [movie, kind])

  const id = movie[`${kind}Id`]

  if (!id) {
    return null
  }

  const rating = movie[`${kind}Rating`]
  const ratingDisplay = rating ? rating.toFixed(1) : '-'
  const maxRating = kind === 'allocine' ? 5 : 10

  let url: string
  if (kind === 'allocine') {
    url = `https://allocine.fr/film/fichefilm_gen_cfilm=${id}.html`
  } else if (kind === 'senscritique') {
    url = `https://senscritique.com/film/page/${id}`
  } else if (kind === 'imdb') {
    url = `https://www.imdb.com/title/${id}`
  } else if (kind === 'mubi') {
    url = `https://mubi.com/fr/fr/films/${id}`
  } else {
    return null
  }

  return (
    <Anchor
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      onClick={trackClick}
      style={{
        fontFamily: 'Josefin Sans, sans-serif',
      }}
    >
      <Stack gap={2} align="center" className="text-black font-normal">
        <div className="w-full h-[30px]">
          <Image src={`/${kind}.png`} h={30} w={30} alt={kind} />
        </div>
        <Group gap={2} className="mb-0 mt-auto">
          <span className="text-sm">{ratingDisplay}</span>
          <span className="text-xs">/{maxRating}</span>
        </Group>
      </Stack>
    </Anchor>
  )
}
