import { Center, Flex, Stack, Text } from '@mantine/core'
import { type ReactElement, type ReactNode, memo, useCallback } from 'react'

import SwipeComponent from 'components/common/SwipeComponent'
import { useIsMobile } from 'providers/isMobileProvider'
import { NavLink, Navigate, useLocation, useNavigate } from 'react-router-dom'

const BAR_HEIGHT_PX = 70

type Props = {
  root: string
  currentTab: string | undefined
  tabs: Array<{
    subroute: string | undefined
    title: string
    icon: ReactNode
    selectedIcon: ReactNode
    content: ReactElement
  }>
}

function PageTabs({ root, currentTab, tabs }: Props) {
  const location = useLocation()
  const navigate = useNavigate()
  const { hasBottomBar } = useIsMobile()
  const rootIndex = tabs.findIndex((element) => element.subroute === undefined)
  const defaultIndex = rootIndex === -1 ? 0 : rootIndex
  const currentIndex = tabs.findIndex(
    (element) => element.subroute === currentTab
  )

  const getUrl = useCallback(
    (subroute: string | undefined) => (subroute ? `${root}/${subroute}` : root),
    [root, tabs]
  )
  const updateUrlForTab = useCallback(
    (index: number) => {
      navigate(getUrl(tabs[index].subroute), {
        state: { hasPrev: location.state?.hasPrev },
        replace: true,
      })
    },
    [navigate, root, tabs, location.state]
  )

  const leftTab = useCallback(
    () => (currentIndex > 0 ? updateUrlForTab(currentIndex - 1) : null),
    [currentIndex, updateUrlForTab]
  )

  const rightTab = useCallback(
    () =>
      currentIndex < tabs.length - 1 ? updateUrlForTab(currentIndex + 1) : null,
    [currentIndex, tabs.length, updateUrlForTab]
  )
  if (currentIndex === -1) {
    return (
      <Navigate
        to={getUrl(tabs[defaultIndex].subroute)}
        state={{ hasPrev: location.state?.hasPrev }}
        replace
      />
    )
  }
  return (
    <Flex direction="column" className="flex-1 overflow-hidden">
      <SwipeComponent
        className="overflow-auto flex-1"
        leftFunc={rightTab}
        rightFunc={leftTab}
      >
        {tabs[currentIndex].content}
      </SwipeComponent>
      <Flex
        align="center"
        className="mb-0 mt-auto w-full bg-zinc-900 mx-0 text-white cursor-pointer text-center"
        style={{ height: `${BAR_HEIGHT_PX}px` }}
      >
        {tabs.map(({ title, icon, selectedIcon, subroute }) => (
          <NavLink
            key={title}
            replace
            to={getUrl(subroute)}
            end
            state={{ hasPrev: location.state?.hasPrev }}
            className={({ isActive }) =>
              `h-full flex-1 ${isActive ? 'font-bold' : 'text-zinc-400'}`
            }
          >
            {({ isActive }) => (
              <Center className="h-full">
                <Stack gap={1}>
                  {isActive ? selectedIcon : icon}
                  <Text size="sm" className="truncated">
                    {title}
                  </Text>
                </Stack>
              </Center>
            )}
          </NavLink>
        ))}
      </Flex>
      {hasBottomBar && <Flex className="h-[10px] bg-zinc-900" />}
    </Flex>
  )
}

export default memo(PageTabs)
