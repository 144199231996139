import { AspectRatio, Image, Text } from '@mantine/core'
import { memo } from 'react'
import { AiFillStar } from 'react-icons/ai'
import type { Movie } from 'types/showtimes'
import EventBand from './EventBand'

type Props = {
  movie: Movie
  hasEvent?: boolean
  displayAVP?: boolean
}

function MovieImage({ movie, hasEvent = false, displayAVP = false }: Props) {
  return (
    <AspectRatio className="overflow-hidden rounded-md relative" ratio={3 / 4}>
      <Image
        src={movie.posterPath}
        height="100%"
        className="transition group-hover:ease-in group-hover:brightness-[110%]"
        styles={{
          root: { width: '100%', height: '100%', minHeight: '100%' },
        }}
        alt={movie.titleVf}
      />
      <div className="align-top flex gap-px absolute bottom-2 right-2 top-auto left-auto border-1 px-2 py-0.5 border-white bg-zinc-800/90 w-fit h-fit rounded-sm items-center aspect-auto">
        <Text className="text-white font-bold text-sm">
          {movie.consolidatedRating != null
            ? movie.consolidatedRating.toFixed(1)
            : '-'}
        </Text>
        <Text className="pl-1 text-[14px] text-yellow-200 group-hover:text-yellow-300 h-[14px]">
          <AiFillStar />
        </Text>
      </div>
      <EventBand hasEvent={hasEvent} isAvantPremiere={displayAVP} />
    </AspectRatio>
  )
}

export default memo(MovieImage)
