import dayjs from 'dayjs'
import { memoize } from 'lodash'
import type { Showtime, Theater } from 'types/showtimes'
import { toLocalTime } from './utils'

const basePrice = (theater: Theater, showtime: Showtime) => {
  // If the theater has a morning price and the showtime is before 12:00
  if (theater.morningPrice && showtime.showtime.hour() < 12) {
    return theater.morningPrice
  }
  return theater.fullPrice
}

export const showtimePrice = (theater: Theater, showtime: Showtime) => {
  let price = basePrice(theater, showtime)
  if (!price) {
    return null
  }
  if (showtime.extraInfo?.includes('ICE')) {
    // Note: 3D is free for ICE showtimes -- prices are complicated so I rounded to 5€
    return price + 5
  }
  if (showtime.is3d) {
    // Assume 2€ for 3D
    price += 2
  }
  if (showtime.extraInfo?.includes('ScreenX')) {
    price += 3
  }
  if (
    ['IMAX', '4D', 'Dolby Cinema'].some((format) =>
      showtime.extraInfo?.includes(format)
    )
  ) {
    // Assume 6€ for IMAX, 4DX and 4D E-motion
    return price + 6
  }
  return price
}

export function capitalize(str: string) {
  if (str.length === 0) {
    return str
  }
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export function fullLanguage(isoCode: string, toLang = 'fr') {
  return capitalize(
    new Intl.DisplayNames([toLang], { type: 'language' }).of(isoCode) as string
  )
}

export function formatPrice(price: number | null) {
  if (price === null) {
    return '-'
  }
  if (price % 1 === 0) {
    return `${Math.round(price)} €`
  }
  return `${price.toFixed(2).replace('.', ',')} €`
}
export function formatAudio(
  vo: boolean,
  audio: string | null,
  detailed = false
) {
  if (detailed) {
    if (audio !== null) {
      const fullAudio = fullLanguage(audio)
      return vo
        ? `Version originale (${fullAudio})`
        : `Version doublée en ${fullAudio}`
    }
    return 'Version originale'
  }
  return vo || audio === null ? 'VO' : `V${audio[0].toUpperCase()}`
}

export function formatSubtitles(subtitles: string[], detailed = false) {
  if (subtitles.length === 0) {
    return ''
  }

  if (detailed) {
    const fullSubtitles = subtitles
      .map((lang) => fullLanguage(lang))
      .filter((lang) => lang !== undefined) as string[]
    return `Sous-titrée ${fullSubtitles.join(' et ')}`
  }
  return ` st ${subtitles.length > 1 ? 'bil.' : subtitles[0].toUpperCase()}`
}

export function formatShowtimeDate(showtimeDate: dayjs.Dayjs) {
  if (showtimeDate.isSame(dayjs(), 'day')) {
    return `Aujourd'hui ${showtimeDate.format('HH:mm')}`
  }
  if (showtimeDate.isSame(dayjs().add(1, 'day'), 'day')) {
    return `Demain ${showtimeDate.format('HH:mm')}`
  }
  if (
    showtimeDate.isBefore(dayjs().add(7, 'day'), 'day') &&
    (showtimeDate.isAfter(dayjs(), 'day') ||
      showtimeDate.isSame(dayjs(), 'day'))
  ) {
    return showtimeDate.format('dddd HH:mm')
  }
  return showtimeDate.format('DD/MM/YYYY HH:mm')
}

export function showtimeIsBefore(
  showtime: dayjs.Dayjs,
  date: dayjs.Dayjs | null
) {
  if (date === null) {
    return false
  }
  return toLocalTime(showtime).isBefore(date)
}

export const showtimePriceText = memoize(
  (theater: Theater, showtime: Showtime) =>
    formatPrice(showtimePrice(theater, showtime)),
  (theater: Theater, showtime: Showtime) =>
    `${theater.id}-${showtime.showtime.hour()}-${showtime.is3d}-${
      showtime.extraInfo
    }`
)

export const showtimeIsUnavailable = (showtime: dayjs.Dayjs) =>
  showtimeIsBefore(showtime, dayjs().subtract(10, 'minute'))

export const showtimeIsAVP = memoize(
  (showtime: dayjs.Dayjs, movieReleaseDate: dayjs.Dayjs | null) =>
    showtimeIsBefore(showtime, movieReleaseDate),
  (showtime: dayjs.Dayjs, movieReleaseDate: dayjs.Dayjs | null) =>
    `${showtime}-${movieReleaseDate}`
)
