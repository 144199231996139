import { ActionIcon } from '@mantine/core'
import { memo, useCallback } from 'react'
import { FaHeart, FaRegHeart } from 'react-icons/fa'

import trackEvent from 'queries/event'
import useSearchStore from 'states/useSearchStore'
import type { Theater } from 'types/showtimes'

type Props = {
  theater: Theater
  size: number
}

function Favorite({ theater, size }: Props) {
  const favoriteTheaterIds = useSearchStore((state) => state.favoriteTheaterIds)
  const toggleFavorite = useSearchStore(
    (state) => state.toggleFavoriteTheaterId
  )
  const isFavorite = favoriteTheaterIds.includes(theater.id)

  const handleClick = useCallback(() => {
    trackEvent('favorite', {
      theaterId: theater.id,
      theaterName: theater.name,
      theater_city: theater.city,
      isFavorite: !isFavorite,
    })
    toggleFavorite(theater.id)
  }, [theater, isFavorite, toggleFavorite])

  return (
    <ActionIcon
      variant="transparent"
      size={size}
      onClick={handleClick}
      className={`transition ease-in ${
        isFavorite ? 'text-red-700 hover:text-red-600' : 'text-zinc-600'
      }`}
    >
      {isFavorite ? <FaHeart size={size} /> : <FaRegHeart size={size} />}
    </ActionIcon>
  )
}

export default memo(Favorite)
