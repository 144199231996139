import { Button, Modal } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { useEffect } from 'react'

function MessageModal({ children }: { children: React.ReactNode }) {
  const [opened, { open, close }] = useDisclosure(true)

  useEffect(() => {
    if (children) {
      open()
    }
  }, [open, children])

  return (
    <Modal
      opened={opened}
      onClose={close}
      withCloseButton={false}
      centered
      className="bg-neutral-900"
      classNames={{
        content: 'bg-neutral-900 text-white text-center',
      }}
      style={{
        fontFamily: 'Josefin Sans, sans-serif',
      }}
    >
      <div className="font-semibold mb-4 leading-7">{children}</div>
      <Button
        size="md"
        color="zinc"
        className="border-white mx-auto mt-4"
        style={{
          fontFamily: 'Josefin Sans, sans-serif',
        }}
        onClick={close}
      >
        Fermer
      </Button>
    </Modal>
  )
}

export default MessageModal
