import { memo } from 'react'

function EventBand({
  hasEvent = false,
  isAvantPremiere = false,
}: {
  hasEvent?: boolean
  isAvantPremiere?: boolean
}) {
  const eventText = hasEvent ? 'EVT' : 'AVP'
  const eventColor = hasEvent ? 'bg-event' : 'bg-avp'
  return isAvantPremiere || hasEvent ? (
    <div
      className={`absolute left-[70%] top-0 w-[calc(1.41*30%)] translate-y-[-100%] rotate-45 ${eventColor} text-center font-bold shadow-md origin-bottom-left aspect-auto`}
    >
      {eventText}
    </div>
  ) : null
}

export default memo(EventBand)
