import { memo, useCallback } from 'react'
import { Link, useLocation } from 'react-router-dom'

import trackEvent from 'queries/event'
import type { Movie, Showtime, Theater } from 'types/showtimes'
import {
  formatSubtitles,
  showtimeIsAVP,
  showtimeIsUnavailable,
  showtimePriceText,
} from 'utils/showtimes'
import ShowtimeBadgeTarget from './ShowtimeBadgeTarget'

type Props = {
  showtime: Showtime
  movie: Movie
  theater: Theater
  isSelected?: boolean
  showPrice?: boolean
}

function ShowtimeBadge({
  showtime,
  movie,
  theater,
  isSelected = false,
  showPrice = false,
}: Props) {
  const location = useLocation()
  const isUnavailable = showtimeIsUnavailable(showtime.showtime)
  const isAvantPremiere = showtimeIsAVP(showtime.showtime, movie.releaseDate)
  const price = showPrice ? showtimePriceText(theater, showtime) : null

  const handleChange = useCallback(() => {
    trackEvent('showtime_click', {
      showtimeId: showtime.id,
      showtime: showtime.showtime,
      url: window.location.href,
    })
  }, [showtime])

  const badge = (
    <ShowtimeBadgeTarget
      showtimeText={showtime.showtime.format('HH:mm')}
      vo={showtime.vo}
      audio={showtime.audio}
      subtitles={formatSubtitles(showtime.subtitles, false)}
      is3d={showtime.is3d}
      extraInfo={showtime.extraInfo}
      isAvantPremiere={isAvantPremiere}
      price={price}
      showPrice={showPrice || false}
      isSelected={isSelected}
      isUnavailable={isUnavailable}
      isEvent={showtime.eventInfo !== null}
    />
  )

  if (isUnavailable) {
    return badge
  }

  // Keep the two first sections of the current path
  const pathStart = location.pathname.split('/').slice(0, 3).join('/')

  return (
    <Link
      to={`${pathStart}/seance/${showtime.id}`}
      state={{ hasPrev: true }}
      onClick={handleChange}
    >
      {badge}
    </Link>
  )
}

export default memo(ShowtimeBadge)
