import { Button } from '@mantine/core'

import { useIsMobile } from 'providers/isMobileProvider'
import trackEvent from 'queries/event'
import useDisplayStore from 'states/useDisplayStore'
import {
  ANDROID_STORE_URL,
  APPLE_STORE_URL,
  globalFunc,
  globalVar,
} from 'utils/utils'

import BottomDrawer from './BottomDrawer'

function InstallOrUpgradeDrawer() {
  const { isMobile, isMobileApp, isIos } = useIsMobile()
  const { requestedInstallOrUpgrade, updateRequestedInstallOrUpgrade } =
    useDisplayStore((state) => ({
      requestedInstallOrUpgrade: state.requestedInstallOrUpgrade,
      updateRequestedInstallOrUpgrade: state.updateRequestedInstallOrUpgrade,
    }))

  // No need to advertise the app on desktop
  if (!isMobile || requestedInstallOrUpgrade) {
    return null
  }

  if (!isMobileApp) {
    return (
      <BottomDrawer
        headerText="La Bobine a une app mobile !"
        onClose={() => updateRequestedInstallOrUpgrade(true)}
      >
        <div className="flex justify-center">
          <Button
            component="a"
            href={isIos ? APPLE_STORE_URL : ANDROID_STORE_URL}
            target="_blank"
            rel="noopener noreferrer"
            onClick={() =>
              trackEvent('app_badge_click', {
                website: isIos ? 'ios' : 'android',
              })
            }
            size="sm"
            color="zinc"
            className="border-white"
            style={{
              fontFamily: 'Josefin Sans, sans-serif',
            }}
          >
            Télécharger
          </Button>
        </div>
      </BottomDrawer>
    )
  }

  const appVersion = globalVar('appVersion')
  const needUpgrade = !appVersion || appVersion < '1.2.2'

  if (!needUpgrade) {
    return null
  }

  if (!isIos && globalVar('upgradeApp')) {
    // Use native Android upgrade dialog
    globalFunc('upgradeApp', () => {})
    return null
  }

  const upgradeUrl = isIos
    ? 'https://apps.apple.com/fr/app/bobine/id6443939999'
    : 'https://play.google.com/store/apps/details?id=com.bobine.bobineart&utm_source=app'

  return (
    <BottomDrawer
      headerText="Une nouvelle version est disponible !"
      onClose={() => updateRequestedInstallOrUpgrade(true)}
    >
      <div className="flex justify-center">
        <Button
          component="a"
          href={upgradeUrl}
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => trackEvent('upgrade_app', {})}
          size="sm"
          color="zinc"
          className="border-white"
          style={{
            fontFamily: 'Josefin Sans, sans-serif',
          }}
        >
          Mettre à jour
        </Button>
      </div>
    </BottomDrawer>
  )
}

export default InstallOrUpgradeDrawer
