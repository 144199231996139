import { MantineProvider } from '@mantine/core'
import React from 'react'
import { createRoot } from 'react-dom/client'

import '@mantine/core/styles.css'
import { QueryClient, QueryClientProvider } from 'react-query'

import App from './App'
import './index.css'
import reportWebVitals from './reportWebVitals'

const queryClient = new QueryClient()
queryClient.setDefaultOptions({ queries: { staleTime: 3600000 } })

const root = createRoot(document.getElementById('root') as Element)
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <MantineProvider
        theme={{
          colors: {
            zinc: [
              '#fafafa',
              '#f4f4f5',
              '#e4e4e7',
              '#d4d4d8',
              '#a1a1aa',
              '#71717a',
              '#52525b',
              '#3f3f46',
              '#27272a',
              '#18181b',
            ],
          },
        }}
      >
        <App />
      </MantineProvider>
    </QueryClientProvider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
