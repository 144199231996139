import { Button, Group, LoadingOverlay, Stack } from '@mantine/core'
import { memo } from 'react'
import { Link } from 'react-router-dom'
import { CalendarPlus, InfoCircle, Share, Ticket } from 'tabler-icons-react'

import CalendarButton from 'components/common/CalendarButton'
import ResponsiveButton from 'components/common/ResponsiveButton'
import ShareButton from 'components/common/ShareButton'
import trackEvent from 'queries/event'
import { useShowtimeReservationUrl } from 'queries/showtimes'
import type ShowtimeContext from 'types/ShowtimeContext'

type Props = {
  showtimeContext: ShowtimeContext
  shareTitle: string
  movieUrl: string
}

function ShowtimeLinks({ showtimeContext, shareTitle, movieUrl }: Props) {
  const {
    showtime: { id: showtimeId },
  } = showtimeContext
  const { data: reservationUrl, isLoading } =
    useShowtimeReservationUrl(showtimeId)

  return (
    <Stack className="w-full mt-2">
      {reservationUrl ? (
        <Button
          component="a"
          leftSection={<Ticket size={24} />}
          className="text-center text-zinc-100 bg-zinc-700 block grow-1"
          disabled={reservationUrl.length === 0}
          href={reservationUrl}
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => trackEvent('reservation_click', { showtimeId })}
        >
          Réserver
        </Button>
      ) : (
        <Button
          className="text-center text-zinc-600 bg-zinc-200 opacity-50"
          disabled
        >
          <LoadingOverlay visible={isLoading} overlayProps={{ blur: 2 }} />
          Réservation indisponible
        </Button>
      )}
      <Group justify="space-between">
        <ShareButton text={shareTitle} className="grow">
          <ResponsiveButton
            icon={<Share size={24} />}
            text="Partager"
            threshold={100}
            showTooltip={false}
            className="w-full text-zinc-800 bg-zinc-50 shadow-md"
          />
        </ShareButton>
        <CalendarButton showtimeContext={showtimeContext} className="grow">
          <ResponsiveButton
            icon={<CalendarPlus size={24} />}
            text="Calendrier"
            threshold={100}
            className="w-full text-zinc-800 bg-zinc-50 shadow-md"
          />
        </CalendarButton>
        <ResponsiveButton
          icon={<InfoCircle size={24} />}
          text="Page du film"
          threshold={100}
          component={Link}
          to={movieUrl}
          state={{ hasPrev: true }}
          className="grow text-center text-zinc-800 bg-zinc-50 shadow-md"
        />
      </Group>
    </Stack>
  )
}
export default memo(ShowtimeLinks)
