import { Grid, Group, Stack } from '@mantine/core'
import { useMemo } from 'react'
import { Link } from 'react-router-dom'

import ShowtimeBadge from 'components/search/moviemodal/ShowtimeBadge'
import MovieDetails from 'components/search/movieposter/MovieDetails'
import MovieImage from 'components/search/movieposter/MovieImage'
import { useCurrentShowtimes } from 'providers/currentShowtimesProvider'
import { useIsMobile } from 'providers/isMobileProvider'
import useDisplayStore from 'states/useDisplayStore'
import useSearchStore from 'states/useSearchStore'
import type { Movie, Showtime, Theater } from 'types/showtimes'

type Props = {
  movie: Movie
  theater: Theater
  showtimes: Showtime[]
}

export default function TheaterMovieCard({ movie, showtimes, theater }: Props) {
  const { isMobile } = useIsMobile()
  const { matchingShowtimes } = useCurrentShowtimes()
  const showPrices = useSearchStore((state) => state.showPrices)
  const date = useDisplayStore((state) => state.currentDate)
  const keyCount: { [key: string]: number } = {}

  const matchingMovieShowtimes = useMemo(
    () => matchingShowtimes.find((s) => s.movie.id === movie.id),
    [matchingShowtimes, movie.id]
  )
  const daySelectedShowtimes = useMemo(
    () =>
      matchingMovieShowtimes?.theaters?.[0]?.showtimes.filter(({ showtime }) =>
        showtime.isSame(date, 'day')
      ) || [],
    [matchingMovieShowtimes, date]
  )

  return (
    <Grid key={movie.id}>
      <Grid.Col span={isMobile ? 5 : 3}>
        <Link to={movie.urlPath} state={{ hasPrev: true }}>
          <MovieImage movie={movie} />
        </Link>
      </Grid.Col>
      <Grid.Col span={isMobile ? 7 : 9}>
        <Stack gap="sm">
          <Link to={movie.urlPath} state={{ hasPrev: true }}>
            <MovieDetails movie={movie} titleLineClamp={2} />
          </Link>

          <Group gap="md" className="flex leading-6 pt-1">
            {showtimes.map((showtime) => {
              const time = showtime.showtime.format('HH:mm')
              keyCount[time] = (keyCount[time] || 0) + 1
              return (
                <ShowtimeBadge
                  movie={movie}
                  theater={theater}
                  showtime={showtime}
                  isSelected={daySelectedShowtimes.some((s) =>
                    s.showtime.isSame(showtime.showtime)
                  )}
                  showPrice={showPrices}
                  key={`${time}-${keyCount[time]}`}
                />
              )
            })}
          </Group>
        </Stack>
      </Grid.Col>
    </Grid>
  )
}
