import { Center, Grid, Image } from '@mantine/core'
import { memo } from 'react'
import { useNavigate } from 'react-router-dom'

import { useIsMobile } from 'providers/isMobileProvider'
import DateSelect from './DateSelect'
import LocationInput from './LocationInput'

function HomeSearchBar() {
  const navigate = useNavigate()
  const { isMobile } = useIsMobile()

  const sizes = isMobile ? [8, 0, 4] : [5, 5, 2]

  return (
    <Center>
      <Grid className="fixed w-[min(80%,700px)] top-[65%] z-10 border-zinc-200 cursor-pointer">
        <Grid.Col span={sizes[0]} className="border bg-white rounded-l-2xl">
          <LocationInput />
        </Grid.Col>
        {isMobile ? null : (
          <Grid.Col span={sizes[1]} className="border bg-white truncate">
            <DateSelect withoutBorder />
          </Grid.Col>
        )}
        <Grid.Col
          span={sizes[2]}
          className="pt-4 rounded-r-2xl border-r border-y bg-zinc-900"
          key="navigate"
          onClick={() => navigate('/search', { state: { hasPrev: true } })}
        >
          <Center>
            <Image className="w-7 h-7" src="/reel_icon.png" />
          </Center>
        </Grid.Col>
      </Grid>
    </Center>
  )
}

export default memo(HomeSearchBar)
