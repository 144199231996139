import { Combobox, Group, Text, useCombobox } from '@mantine/core'
import { useIsMobile } from 'providers/isMobileProvider'
import trackEvent from 'queries/event'
import useSearchStore from 'states/useSearchStore'
import { SortAscending, SortDescending } from 'tabler-icons-react'

const ORDERS = {
  consolidated_rating: 'Note',
  title_vf: 'Titre',
  duration: 'Durée',
  release_date: 'Date de sortie',
  next_showtime: 'Prochaine séance',
}

function OrderSelector() {
  const orderBy =
    useSearchStore((state) => state.searchParams.orderBy) ||
    'consolidated_rating'
  const orderDir = useSearchStore((state) => state.searchParams.orderDir)
  const updateSearchParams = useSearchStore((state) => state.updateSearchParams)
  const { isMobile } = useIsMobile()

  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
  })

  return (
    <Combobox
      store={combobox}
      width={230}
      position="bottom-end"
      zIndex={10}
      withArrow
      onOptionSubmit={(val: string) => {
        trackEvent('change_order', { orderBy: val })
        if (val === orderBy) {
          updateSearchParams({
            orderDir: orderDir === 'asc' ? undefined : 'asc',
          })
        } else if (val === 'consolidated_rating') {
          updateSearchParams({
            orderBy: undefined,
            orderDir: undefined,
          })
        } else {
          updateSearchParams({
            orderBy: val as keyof typeof ORDERS,
            orderDir: 'asc',
          })
        }
      }}
    >
      <Combobox.Target>
        <Group
          className={`ml-auto w-fit mr-1 cursor-pointer select-none ${isMobile ? 'pt-3 pb-2' : 'pt-4'}`}
          gap={6}
          onClick={() => combobox.toggleDropdown()}
        >
          <Text>trié par</Text>
          <Text fw={700}>{ORDERS[orderBy]}</Text>
          {orderDir === 'asc' ? (
            <SortAscending size={20} />
          ) : (
            <SortDescending size={20} />
          )}
        </Group>
      </Combobox.Target>
      <Combobox.Dropdown>
        <Combobox.Options>
          {Object.entries(ORDERS).map(([key, value]) => (
            <Combobox.Option
              key={key}
              value={key}
              className={`h-12 align-middle ${key === orderBy ? 'bg-gray-100' : ''}`}
            >
              <Group justify="space-between" className="h-full">
                {key === orderBy ? (
                  <>
                    <Text fw={700}>{value}</Text>
                    <Group gap={1}>
                      <SortDescending
                        size={20}
                        color={orderDir === 'asc' ? '#bbb' : 'black'}
                      />
                      <SortAscending
                        size={20}
                        color={orderDir === 'asc' ? 'black' : '#bbb'}
                      />
                    </Group>
                  </>
                ) : (
                  <Text>{value}</Text>
                )}
              </Group>
            </Combobox.Option>
          ))}
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  )
}

export default OrderSelector
