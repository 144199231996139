import {
  Flex,
  Grid,
  Group,
  LoadingOverlay,
  ScrollArea,
  Stack,
  Title,
} from '@mantine/core'
import { Helmet } from 'react-helmet'
import { BsTicketPerforated, BsTicketPerforatedFill } from 'react-icons/bs'
import {
  HiBuildingStorefront,
  HiOutlineBuildingStorefront,
} from 'react-icons/hi2'
import { Navigate, useLocation, useParams } from 'react-router-dom'

import PageTabs from 'components/common/PageTabs'
import MobileModalHeader from 'components/search/genericmodal/MobileModalHeader'
import ModalHeader from 'components/search/genericmodal/ModalHeader'
import ShowtimesTab from 'components/search/moviemodal/ShowtimesTab'
import { useCurrentShowtimes } from 'providers/currentShowtimesProvider'
import {
  CurrentTheaterProvider,
  useCurrentTheater,
} from 'providers/currentTheaterProvider'
import { useIsMobile } from 'providers/isMobileProvider'
import Favorite from './Favorite'
import TheaterDescription from './TheaterDescription'
import TheaterMovieList from './TheaterMovieList'

type RouteParams = {
  theaterId: string
  theaterUrlTitle: string
  tab: string
  showtimeId?: string
}

function TheaterContent({
  currentTab,
}: {
  currentTab: string | undefined
}) {
  const location = useLocation()
  const { isMobile } = useIsMobile()
  const { theater } = useCurrentTheater()
  const { allShowtimes } = useCurrentShowtimes()

  const description = `Retrouvez toutes les séances des films au programme du cinéma ${theater.name} à ${theater.city}. Horaires des séances, bandes-annonces et réservation en ligne.`

  const movies = allShowtimes || []
  const helmet = (
    <Helmet>
      <title>
        Cinéma {theater.name} ({theater.city}) - Programme - La Bobine
      </title>
      <meta name="description" content={description} />
      <meta
        property="og:title"
        content={`Cinéma ${theater.name} (${theater.city}) - Programme`}
      />
      <meta property="og:description" content={description} />
      <meta
        property="og:url"
        content={`${window.location.origin}${theater.urlPath}`}
      />
      <link
        rel="canonical"
        href={`${window.location.origin}${theater.urlPath}`}
      />
      <script type="application/ld+json">
        {JSON.stringify({
          '@context': 'https://schema.org',
          '@type': 'ItemList',
          itemListElement: movies.map((movie, index) => ({
            '@type': 'ListItem',
            position: index + 1,
            url: `${window.location.origin}${movie.movie.urlPath}`,
          })),
        })}
      </script>
      <link
        rel="stylesheet"
        href="https://unpkg.com/leaflet@1.9.4/dist/leaflet.css"
        integrity="sha256-p4NxAoJBhIIN+hmNHrzRCf9tD/miZyoHS5obTRR9BMY="
        crossOrigin=""
      />
      <script
        src="https://unpkg.com/leaflet@1.9.4/dist/leaflet.js"
        integrity="sha256-20nQCchB9co0qIjJZRGuk2/Z9VM+kNiyxNV1lvTlZBo="
        crossOrigin=""
      />
    </Helmet>
  )

  const showtimesTab = (
    <ShowtimesTab>
      <ScrollArea offsetScrollbars className="h-full w-full" type="auto">
        <TheaterMovieList />
      </ScrollArea>
    </ShowtimesTab>
  )
  const mobileHeader = (
    <>
      <MobileModalHeader className="mb-2">
        <Favorite theater={theater} size={25} />
      </MobileModalHeader>
      <Title
        order={1}
        c="zinc.9"
        className="font-semibold text-2xl !uppercase truncate"
        style={{
          fontFamily: 'Josefin Sans, sans-serif',
        }}
      >
        {theater.name}
      </Title>
    </>
  )

  const showtimesContent = (
    <Flex
      direction="column"
      gap={1}
      className="w-full h-full p-[10px] text-zinc-900"
    >
      {mobileHeader}
      {showtimesTab}
    </Flex>
  )

  const keyTabs = [
    {
      subroute: 'info',
      title: 'Informations',
      icon: <HiOutlineBuildingStorefront size={30} className="mx-auto" />,
      selectedIcon: <HiBuildingStorefront size={30} className="mx-auto" />,
      content: (
        <Flex direction="column" gap={1} className="h-full p-[10px]">
          {mobileHeader}
          <TheaterDescription theater={theater} />
        </Flex>
      ),
    },
    {
      subroute: undefined,
      title: `A l'affiche`,
      icon: <BsTicketPerforated size={30} className="mx-auto" />,
      selectedIcon: <BsTicketPerforatedFill size={30} className="mx-auto" />,
      content: showtimesContent,
    },
  ]
  if (isMobile) {
    return (
      <Flex className="h-[var(--page-height)] bg-white">
        {helmet}
        <PageTabs
          root={theater.urlPath}
          tabs={keyTabs}
          currentTab={currentTab}
        />
      </Flex>
    )
  }

  if (currentTab !== undefined) {
    return (
      <Navigate
        to={theater.urlPath}
        state={{ hasPrev: location.state?.hasPrev }}
        replace
      />
    )
  }

  return (
    <Flex
      direction="column"
      className="bg-white h-[calc(100*var(--vh)-var(--header-height))] overflow-hidden"
    >
      {helmet}
      <div className="flex-none">
        <ModalHeader
          className="mt-2"
          linkTitle={theater.name || ''}
          url={`${window.location.origin}${theater.urlPath}`}
        >
          <Group className="flex flex-nowrap">
            <Title
              order={1}
              lineClamp={2}
              fw={300}
              className="text-3xl mr-auto font-light"
            >
              {theater.name}
            </Title>
            <Favorite theater={theater} size={25} />
          </Group>
        </ModalHeader>
      </div>
      <Flex direction="column" className="flex-1 overflow-hidden mx-auto">
        <Grid
          columns={24}
          gutter={0}
          grow
          className="max-w-6xl w-full flex-1 h-full"
          classNames={{ inner: 'h-full' }}
          m={0}
        >
          <Grid.Col className="h-full overflow-y-auto" span={12}>
            <TheaterDescription theater={theater} />
          </Grid.Col>
          <Grid.Col span={11} offset={1} className="overflow-y-auto h-full">
            <Stack className="h-full relative">
              <>
                <LoadingOverlay
                  visible={allShowtimes === undefined}
                  overlayProps={{ blur: 2 }}
                />
                {showtimesTab}
              </>
            </Stack>
          </Grid.Col>
        </Grid>
      </Flex>
    </Flex>
  )
}

export default function TheaterPage() {
  const { tab } = useParams<RouteParams>()

  return (
    <CurrentTheaterProvider>
      <TheaterContent currentTab={tab} />
    </CurrentTheaterProvider>
  )
}
