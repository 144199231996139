import dayjs from 'dayjs'

import { globalVar } from 'utils/utils'

const DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ss[Z]'

function snakeCase(str: string): string {
  return str.replace(/([A-Z])/g, '_$1').toLowerCase()
}

function formatKeyValue(key: string, value: any): string {
  const snakeKey = snakeCase(key)
  if (Array.isArray(value)) {
    return value.map((v) => formatKeyValue(snakeKey, v)).join('&')
  }
  if (dayjs.isDayjs(value)) {
    return `${snakeKey}=${value.format(DATE_FORMAT)}`
  }
  return `${snakeKey}=${value}`
}

function queryString(params: any): string {
  // Build a query string from an object, converting keys to snake_case
  return Object.keys(params)
    .map((key) => formatKeyValue(key, params[key]))
    .join('&')
}

function requestHeaders(values: any): Headers {
  const headers = new Headers(values)

  const appVersion = globalVar<string>('appVersion')
  if (appVersion) {
    headers.append('X-App-Version', appVersion)
  }

  return headers
}

export default function apiFetch<T>(
  url: string,
  params?: any
): () => Promise<T> {
  return async () => {
    const paramsString = params ? `?${queryString(params)}` : ''
    const response = await fetch(`${url}${paramsString}`, {
      headers: requestHeaders({}),
    })

    if (!response.ok) {
      throw new Error(response.statusText)
    }

    return response.json()
  }
}

export function apiPost<T>(url: string, body: any): () => Promise<T> {
  return async () => {
    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: requestHeaders({ 'Content-Type': 'application/json' }),
    })

    if (!response.ok) {
      throw new Error(response.statusText)
    }

    return response.json()
  }
}
