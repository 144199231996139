import { Center, Flex, Stack } from '@mantine/core'
import React from 'react'

import MovieRating from './MovieRating'

type Props = {
  className?: string
}

function MovieGrid({ className = '' }: Props) {
  return (
    <Stack className={className}>
      <Center>
        <Flex direction="row" gap={20}>
          <MovieRating kind="allocine" />
          <MovieRating kind="senscritique" />
          <MovieRating kind="imdb" />
          <MovieRating kind="mubi" />
        </Flex>
      </Center>
    </Stack>
  )
}

export default React.memo(MovieGrid)
