import { Flex, List, Space, Stack, Text } from '@mantine/core'
import LeafLet from 'leaflet'
import React, { useEffect } from 'react'
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet'
import useSearchStore from 'states/useSearchStore'
import type { Theater } from 'types/showtimes'
import getDistanceInMeters from 'utils/distance'
import TheaterAnchor, { mapUrl } from './TheaterAnchor'

type Props = {
  theater: Theater
}

function TheaterDescription({ theater }: Props) {
  const { latitude: theaterLatitude, longitude: theaterLongitude } = theater
  const { latitude: userLatitude, longitude: userLongitude } = useSearchStore(
    (state) => state.searchParams
  )
  const distance =
    userLatitude && userLongitude
      ? getDistanceInMeters(
          { latitude: theaterLatitude, longitude: theaterLongitude },
          { latitude: userLatitude, longitude: userLongitude }
        )
      : null

  // prevent propagation when dragging map
  useEffect(() => {
    const stopProp = (e: Event) => {
      e.stopPropagation()
    }
    const map = document.querySelector('.leaflet-container')
    map?.addEventListener('mousedown', stopProp)

    return () => map?.removeEventListener('mousedown', stopProp)
  }, [])

  return (
    <Stack className="w-full">
      <Space h="xs" />

      <div className="w-full flex justify-between">
        <Text className="flex-1 pr-2 font-light block" size="md" lineClamp={3}>
          <Text size="md" className="pr-2 inline font-medium">
            Adresse{' '}
          </Text>
          <a href={mapUrl(theater)}>{theater.address}</a>
        </Text>
        <div className="flex-none">
          <TheaterAnchor
            theater={{ ...theater, distance: distance ?? theater.distance }}
          />
        </div>
      </div>
      <MapContainer
        className="w-full h-64 z-0"
        center={[theaterLatitude, theaterLongitude]}
        scrollWheelZoom={false}
        zoom={13}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <Marker
          position={[theaterLatitude, theaterLongitude]}
          icon={
            new LeafLet.Icon({
              iconUrl: '/theater_location.png',
              iconSize: [32, 32],
            })
          }
        >
          <Popup>
            <div className="flex h-full items-center w-fit">
              <div className="align-middle max-w-[120px] pr-4">
                <Text lineClamp={2}>{theater.name}</Text>
              </div>
              <TheaterAnchor
                theater={{
                  ...theater,
                  distance: distance ?? theater.distance,
                }}
              />
            </div>
          </Popup>
        </Marker>
      </MapContainer>

      <Flex direction="column" gap={2}>
        <Text size="md" className="font-medium pr-2">
          Cartes acceptées
        </Text>
        <List className="list-disc ml-2">
          {theater.cards.map((card) => (
            <List.Item key={card}>
              <Text size="md" className="font-light pr-2">
                {card}
              </Text>
            </List.Item>
          ))}
        </List>
      </Flex>
    </Stack>
  )
}

export default React.memo(TheaterDescription)
