type Point = {
  latitude: number
  longitude: number
}

export default function getDistanceInMeters(
  point1: Point,
  point2: Point
): number {
  const R = 6371e3 // rayon de la Terre en mètres
  const lat1Rad = point1.latitude * (Math.PI / 180) // conversion de degrés à radians
  const lat2Rad = point2.latitude * (Math.PI / 180)
  const deltaLat = (point2.latitude - point1.latitude) * (Math.PI / 180)
  const deltaLon = (point2.longitude - point1.longitude) * (Math.PI / 180)

  const a =
    Math.sin(deltaLat / 2) * Math.sin(deltaLat / 2) +
    Math.cos(lat1Rad) *
      Math.cos(lat2Rad) *
      Math.sin(deltaLon / 2) *
      Math.sin(deltaLon / 2)
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))

  const distance = R * c // distance en mètres
  return distance
}

export function formatDistance(distance: number) {
  if (distance < 100) {
    return '<100m'
  }
  const hundreds = Math.round(distance / 100)
  if (distance < 950) {
    return `${hundreds}00 m`
  }
  if (distance < 9500) {
    return `${hundreds / 10} km`
  }
  const km = Math.round(hundreds / 10)
  return `${km} km`
}
