import { Accordion, Checkbox, Grid, Group, Text, Title } from '@mantine/core'
import { memo } from 'react'
import useSearchStore from 'states/useSearchStore'
import { fullLanguage } from 'utils/showtimes'

const LANGUAGES = [
  'fr',
  'en',
  'es',
  'it',
  'de',
  'pt',
  'ja',
  'zh',
  'ko',
  'hi',
  'ar',
  'fa',
  'ru',
  'pl',
  'nl',
  'sv',
  'no',
  'da',
  'el',
  'ro',
  'tr',
  'he',
]

function AudioLangSelect() {
  const audioLangs = useSearchStore((state) => state.searchParams.audioLangs)
  const updateSearchParams = useSearchStore((state) => state.updateSearchParams)

  return (
    <Accordion.Item value="audioLang">
      <Accordion.Control>
        <Group gap={6}>
          <Title order={5}>Langue audio</Title>
          {audioLangs && audioLangs.length > 0 && (
            <Text className="font-base text-gray-500">
              ({audioLangs.length})
            </Text>
          )}
        </Group>
      </Accordion.Control>
      <Accordion.Panel>
        <Grid>
          {LANGUAGES.map((lang) => (
            <Grid.Col span={6} key={lang}>
              <Checkbox
                label={fullLanguage(lang)}
                checked={audioLangs?.includes(lang) || false}
                onChange={() => {
                  if (audioLangs?.includes(lang)) {
                    updateSearchParams({
                      audioLangs: audioLangs.filter((l) => l !== lang),
                    })
                  } else {
                    updateSearchParams({
                      audioLangs: audioLangs?.concat(lang) || [lang],
                    })
                  }
                }}
                color="zinc.6"
              />
            </Grid.Col>
          ))}
        </Grid>
      </Accordion.Panel>
    </Accordion.Item>
  )
}

export default memo(AudioLangSelect)
