import {
  formatShowtimeDate,
  showtimeIsAVP,
  showtimePriceText,
} from 'utils/showtimes'
import type { Movie, Showtime, Theater } from './showtimes'

export default class ShowtimeContext {
  public showtime: Showtime
  public movie: Movie
  public theater: Theater
  constructor(showtime: Showtime, movie: Movie, theater: Theater) {
    this.showtime = showtime
    this.movie = movie
    this.theater = theater
  }

  get showtimeDateStr() {
    return formatShowtimeDate(this.showtime.showtime)
  }

  get price() {
    return showtimePriceText(this.theater, this.showtime)
  }

  get isAvantPremiere() {
    return showtimeIsAVP(this.showtime.showtime, this.movie.releaseDate)
  }

  get shareTitle() {
    return `${this.movie.titleVf}, ${this.showtimeDateStr} au ${this.theater.name}`
  }
}
