import { type Context, useContext } from 'react'

export default function useValidContext<T>(context: Context<T | null>): T {
  const value = useContext(context)
  if (value === null) {
    throw new Error(
      `Context must be used within a ${context.displayName} provider`
    )
  }
  return value
}
