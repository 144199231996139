import { Anchor, Group, Stack, Text } from '@mantine/core'
import trackEvent from 'queries/event'
import { Location } from 'tabler-icons-react'
import type { Theater } from 'types/showtimes'
import { formatDistance } from 'utils/distance'

export const mapUrl = (theater: Theater) => {
  const query = encodeURIComponent(`${theater.name} ${theater.address}`)
  return `https://www.google.com/maps/search/?api=1&query=${query}`
}

const trackMapClick = (theater: Theater) =>
  trackEvent('address_map', {
    theaterId: theater.id,
    theaterName: theater.name,
    theaterCity: theater.city,
  })

function TheaterAnchor({ theater }: { theater: Theater }) {
  const theaterUrl = mapUrl(theater)

  return (
    <Anchor
      href={theaterUrl}
      onClick={() => trackMapClick(theater)}
      target="_blank"
      className="truncate"
    >
      <Stack align="flex-end" gap={0}>
        <Group className="flex-nowrap" justify="flex-start" gap={4}>
          <Location className="text-zinc-600" size="14" />
          <Text color="zinc.6" size="xs">
            {theater.distance ? formatDistance(theater.distance) : '--'}
          </Text>
        </Group>
        <Text color="zinc.6" size="xs">
          {theater.city}
        </Text>
      </Stack>
    </Anchor>
  )
}

export default TheaterAnchor
