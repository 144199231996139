import { APPLE_STORE_URL } from 'utils/utils'
import GenericBadge from './GenericBadge'

type Props = {
  scale?: number
}

export default function AppleBadge({ scale = 1 }: Props) {
  const aspectRatio = 193 / 61
  return (
    <GenericBadge
      url={APPLE_STORE_URL}
      imgSrc="/apple_store_badge.svg"
      imgAlt="Télécharger dans l'App Store"
      aspectRatio={aspectRatio}
      scale={scale}
      badgeType="ios"
    />
  )
}
