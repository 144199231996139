import { AspectRatio, Container, Image, ScrollArea, Stack } from '@mantine/core'
import { memo } from 'react'

import { useCurrentMovie } from 'providers/currentMovieProvider'
import { useIsMobile } from 'providers/isMobileProvider'
import MovieGrid from './MovieGrid'
import TrailerVideo from './TrailerVideo'

function SynopsisTab() {
  const { isMobile } = useIsMobile()
  const { movie } = useCurrentMovie()
  const { synopsis, posterHDPath, trailerYoutubeId, trailerThumbnailPath } =
    movie

  return (
    <Container fluid px={0} className="h-full overflow-hidden">
      <ScrollArea type="auto" offsetScrollbars={!isMobile} className="h-full">
        <Stack gap="sm" className="h-full pb-3">
          <MovieGrid className="px-2" />

          {synopsis && (
            <>
              <div className="mx-2">SYNOPSIS</div>
              <div className="text-sm mx-4">{synopsis}</div>
            </>
          )}

          {trailerYoutubeId && trailerThumbnailPath ? (
            <>
              <div className="mx-2">BANDE ANNONCE</div>
              <div>
                <TrailerVideo
                  movie={movie}
                  className="relative w-[85%] max-w-[30rem] mx-auto mb-2"
                />
              </div>
            </>
          ) : (
            isMobile && (
              <AspectRatio
                className="overflow-hidden w-[55%] mx-auto my-4"
                ratio={3 / 4}
              >
                <Image src={posterHDPath} height="100%" />
              </AspectRatio>
            )
          )}
        </Stack>
      </ScrollArea>
    </Container>
  )
}

export default memo(SynopsisTab)
