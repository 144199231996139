import { useQuery } from 'react-query'
import { type Movie, apiToMovie } from 'types/showtimes'
import apiFetch from './common'

export async function movieById(movieId: string | number): Promise<Movie> {
  const data = await apiFetch(`/api/movies/${movieId}`)()
  return apiToMovie(data)
}

async function movieSearch(searchText: string, page: number, pageSize: number) {
  const params = { searchText, page, pageSize }
  const movies: any = await apiFetch('/api/movies/search', params)()
  return movies.map(apiToMovie)
}

export function useMoviesSearch(
  searchText: string,
  page: number,
  pageSize: number,
  { enabled = true } = {}
) {
  return useQuery<Movie[], Error>(
    ['movies_search', searchText, page, pageSize],
    () => movieSearch(searchText, page, pageSize),
    { enabled }
  )
}
