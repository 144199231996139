import { Container } from '@mantine/core'
import { useIsMobile } from 'providers/isMobileProvider'
import { memo } from 'react'
import { Link } from 'react-router-dom'

function Logo() {
  const { isMobile } = useIsMobile()

  return (
    <Container fluid style={{ position: 'absolute', top: 0, left: 0 }} px={0}>
      <Link to="/">
        <div className="absolute top-0 left-0 w-[70px] h-full" />
      </Link>
      <img src={isMobile ? '/mobile_logo.svg' : '/logo.svg'} alt="logo" />
    </Container>
  )
}

export default memo(Logo)
