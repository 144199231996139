import { Button } from '@mantine/core'
import React, { useCallback } from 'react'

import useFiltersCount from 'hooks/useFiltersCount'
import trackEvent from 'queries/event'
import useSearchStore from 'states/useSearchStore'

function OtherOptionsReset() {
  const filtersCount = useFiltersCount()
  const resetFilters = useSearchStore((state) => state.resetFilters)

  const handleReset = useCallback(() => {
    trackEvent('reset_filters', {})
    resetFilters()
  }, [resetFilters])

  return (
    <div className="flex justify-center mx-auto">
      <Button
        variant="outline"
        color="zinc.9"
        disabled={filtersCount === 0}
        onClick={handleReset}
        className="disabled:bg-zinc-100"
      >
        Réinitialiser les filtres
      </Button>
    </div>
  )
}

export default React.memo(OtherOptionsReset)
