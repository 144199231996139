import { useCallback } from 'react'

import useAutocompleteServices from 'components/search/placesApi'
import defaultLocation from 'queries/location'
import type { ILocationUtils } from 'states/useLocationStore'
import useSearchStore from 'states/useSearchStore'
import type { Place } from 'types/showtimes'
import { DEFAULT_ADDRESS } from 'utils/utils'

const API_KEY = 'AIzaSyDqeb3hHTOvMP2LBTaZF5anemt4bRgDtus'

type DefaultLocation = {
  description: string
  latitude: number
  longitude: number
}

export const DEFAULT_LOCATIONS: { [key: string]: DefaultLocation } = {
  paris: {
    description: 'Paris, France',
    latitude: 48.856614,
    longitude: 2.3522219,
  },
  bruxelles: {
    description: 'Bruxelles, Belgique',
    latitude: 50.8476424,
    longitude: 4.3571696,
  },
  lyon: {
    description: 'Lyon, France',
    latitude: 45.764043,
    longitude: 4.835659,
  },
  marseille: {
    description: 'Marseille, France',
    latitude: 43.296482,
    longitude: 5.36978,
  },
  toulouse: {
    description: 'Toulouse, France',
    latitude: 43.604652,
    longitude: 1.444209,
  },
  lille: {
    description: 'Lille, France',
    latitude: 50.62925,
    longitude: 3.057256,
  },
}
const DEFAULT_INACTIVE_SERVICE: ILocationUtils = {
  findLocation: () => {},
  placePredictions: [],
  getPlacePredictions: () => {},
}

function useLocationService(): ILocationUtils {
  const {
    autocompleteSessionToken,
    placesService,
    placePredictions,
    getPlacePredictions,
    refreshSessionToken,
  } = useAutocompleteServices(API_KEY, {
    componentRestrictions: {
      country: ['fr', 'be', 'tf', 'gp', 'mq', 're', 'pm', 'bl'],
    },
  })

  const updateSearchLocation = useSearchStore(
    (state) => state.updateSearchLocation
  )
  const updatePlace = useSearchStore((state) => state.updatePlace)

  const getCurrentLocation = useCallback(
    (callback: (latitude: number, longitude: number) => void) => {
      const defaultBehavior = () => {
        defaultLocation().then(({ latitude, longitude }) =>
          callback(latitude, longitude)
        )
      }
      // Fetch either location in navigator or default location from IP
      if ('geolocation' in navigator) {
        navigator.geolocation.getCurrentPosition(
          ({ coords }) => callback(coords.latitude, coords.longitude),
          (error) => {
            if (error.code === error.TIMEOUT) {
              // If geolocation timed out, try retrieving a cached location
              navigator.geolocation.getCurrentPosition(
                ({ coords }) => callback(coords.latitude, coords.longitude),
                defaultBehavior,
                { maximumAge: Number.POSITIVE_INFINITY, timeout: 1000 }
              )
            }
            defaultBehavior()
          },
          { maximumAge: 60000, timeout: 5000 }
        )
      } else {
        defaultBehavior()
      }
    },
    []
  )

  const findLocation = useCallback(
    (place: Place) => {
      // Either current position, a default location or an address from Maps Autocomplete
      updatePlace(place)

      if (place.address === DEFAULT_ADDRESS) {
        getCurrentLocation(updateSearchLocation)
      } else if (place.id !== null && place.id in DEFAULT_LOCATIONS) {
        const { latitude, longitude } = DEFAULT_LOCATIONS[place.id]
        updateSearchLocation(latitude, longitude)
      } else if (place.id) {
        placesService?.getDetails(
          {
            placeId: place.id,
            fields: ['geometry.location'],
            sessionToken: autocompleteSessionToken,
          },
          (placeDetails: any) => {
            const latitude = placeDetails.geometry.location.lat()
            const longitude = placeDetails.geometry.location.lng()
            updateSearchLocation(latitude, longitude)
            refreshSessionToken()
          }
        )
      }
    },
    [
      placesService,
      refreshSessionToken,
      updatePlace,
      updateSearchLocation,
      getCurrentLocation,
      autocompleteSessionToken,
    ]
  )

  const displayedPlacePredictions =
    placePredictions === undefined
      ? Object.keys(DEFAULT_LOCATIONS).map((key) => ({
          place_id: key,
          description: DEFAULT_LOCATIONS[key].description,
        }))
      : placePredictions

  if (autocompleteSessionToken == null) {
    // wait AutoCompleteServices to be initialized before to use it in this service
    return DEFAULT_INACTIVE_SERVICE
  }

  return {
    findLocation,
    placePredictions: displayedPlacePredictions,
    getPlacePredictions,
  }
}

export default useLocationService
