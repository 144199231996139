import { type ReactElement, memo, useState } from 'react'

// the required distance between touchStart and touchEnd to be detected as a swipe
const MIN_SWIPE_DISTANCE = 50

type Props = {
  leftFunc?: (() => void) | null
  rightFunc?: (() => void) | null
  children: ReactElement
  className?: string
}

type Coordinates = {
  x: number
  y: number
}

function SwipeComponent({
  leftFunc = null,
  rightFunc = null,
  children,
  className = '',
}: Props) {
  const [touchStart, setTouchStart] = useState<Coordinates | null>(null)
  const [touchEnd, setTouchEnd] = useState<Coordinates | null>(null)

  const onTouchStart = (event: any) => {
    setTouchEnd(null) // otherwise the swipe is fired even with usual touch events
    setTouchStart({
      x: event.targetTouches[0].clientX,
      y: event.targetTouches[0].clientY,
    })
  }

  const onTouchMove = (event: any) =>
    setTouchEnd({
      x: event.targetTouches[0].clientX,
      y: event.targetTouches[0].clientY,
    })

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) {
      return
    }
    const distanceX = touchStart.x - touchEnd.x
    const distanceY = touchStart.y - touchEnd.y
    const isHorizontalSwipe = Math.abs(distanceY) < Math.abs(distanceX)
    if (!isHorizontalSwipe) {
      return
    }
    const isLeftSwipe = distanceX > MIN_SWIPE_DISTANCE
    const isRightSwipe = distanceX < -MIN_SWIPE_DISTANCE
    if (isRightSwipe && rightFunc) {
      rightFunc()
    } else if (isLeftSwipe && leftFunc) {
      leftFunc()
    }
  }
  return (
    <div
      className={className}
      onTouchStart={onTouchStart}
      onTouchEnd={onTouchEnd}
      onTouchMove={onTouchMove}
    >
      {children}
    </div>
  )
}

export default memo(SwipeComponent)
