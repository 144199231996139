import dayjs from 'dayjs'
import 'dayjs/locale/fr'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)
dayjs.extend(customParseFormat)

const ZIPCODE_RE = /\d{4,5}/
const WORD_LIMIT_RE = / |\B(?=[A-Z])/

export interface Showtime {
  id: number
  showtime: dayjs.Dayjs
  vo: boolean
  audio: string | null
  subtitles: string[]
  is3d: boolean
  extraInfo: string | null
  eventInfo: string | null
}

export interface Theater {
  id: number
  name: string
  address: string
  city: string
  zipCode: string
  country: string
  latitude: number
  longitude: number
  distance: number
  fullPrice: number | null
  morningPrice: number | null
  showtimes: Showtime[]
  urlTitle: string
  urlPath: string
  cards: string[]
}

export interface Movie {
  id: number
  titleVo: string
  titleVf: string
  duration: number
  releaseDate: dayjs.Dayjs | null
  synopsis: string
  posterPath: string
  posterHDPath: string
  stillPath: string | null
  casting: string | null
  director: string | null
  countries: string | null
  genres: string | null
  allocineId: string | null
  allocineRating: number | null
  allocineNbRatings: number | null
  senscritiqueId: string | null
  senscritiqueRating: number | null
  senscritiqueNbRatings: number | null
  mubiId: string | null
  mubiRating: number | null
  mubiNbRatings: number | null
  imdbId: string | null
  imdbRating: number | null
  imdbNbRatings: number | null
  consolidatedRating: number | null
  totalNbRatings: number
  forChildren: boolean
  trailerYoutubeId: string
  trailerThumbnailPath: string
  urlPath: string
  urlTitle: string
}

export interface MovieWithShowtimes {
  movie: Movie
  theaters: Theater[]
}

function apiToShowtime(json: any): Showtime {
  const subtitles: string[] = []
  if (json.sub1_lang) {
    subtitles.push(json.sub1_lang)
  }
  if (json.sub2_lang) {
    subtitles.push(json.sub2_lang)
  }
  return {
    id: json.id,
    showtime: dayjs.utc(json.showtime).locale('fr'),
    vo: json.vo,
    audio: json.audio_lang,
    subtitles,
    is3d: json.is_3d,
    extraInfo: json.extra_info,
    eventInfo: json.event_info,
  }
}

function extractCityFromAddress(address: string) {
  const zipCode = address.match(ZIPCODE_RE)
  if (zipCode) {
    const zipCodeNumber = Number.parseInt(zipCode[0], 10)
    if (zipCodeNumber >= 13001 && zipCodeNumber <= 13016) {
      return `Marseille ${zipCodeNumber % 100}e`
    }
    if (zipCodeNumber >= 69001 && zipCodeNumber <= 69009) {
      return `Lyon ${zipCodeNumber % 100}e`
    }
    if (zipCodeNumber >= 75001 && zipCodeNumber <= 75020) {
      return `Paris ${zipCodeNumber % 100}e`
    }
  }
  const parts = address.split(ZIPCODE_RE)
  return parts.at(-1)?.trim() ?? ''
}

export function stringToUrlTitle(text: string): string {
  return (
    text
      .normalize('NFD')
      // biome-ignore lint/suspicious/noMisleadingCharacterClass: not sure what's the best fix here
      .replace(/[\u0300-\u036f]/g, '')
      .replace(/\W+/g, ' ')
      .trim()
      .split(WORD_LIMIT_RE)
      .slice(0, 7)
      .map((word: string) => word.toLowerCase())
      .join('-')
  )
}

const COUNTRIES = {
  FR: 'France',
  BE: 'Belgique',
}

export function apiToTheater(json: any): Theater {
  return {
    id: json.id,
    name: json.name,
    latitude: json.latitude,
    longitude: json.longitude,
    address: json.address,
    city: extractCityFromAddress(json.address),
    zipCode: json.address.match(ZIPCODE_RE)?.[0] ?? '',
    country:
      COUNTRIES[json.country_code as keyof typeof COUNTRIES] ??
      json.country_code,
    distance: json.distance,
    fullPrice: json.full_price,
    morningPrice: json.morning_price,
    showtimes: json.showtimes ? json.showtimes.map(apiToShowtime) : [],
    urlTitle: stringToUrlTitle(json.name),
    urlPath: `/cinema/${stringToUrlTitle(json.name)}-${json.id}`,
    cards: json.cards ?? [],
  }
}

export function apiToMovie(json: any): Movie {
  return {
    id: json.id,
    titleVo: json.title_vo,
    titleVf: json.title_vf,
    duration: json.duration,
    releaseDate: json.release_date === null ? null : dayjs(json.release_date),
    synopsis: json.synopsis,
    posterPath: json.poster_path,
    posterHDPath: json.poster_path?.replace('r_460_600', 'r_900_1200'),
    stillPath: json.still_path?.replace('size=800x', 'size=1280x'),
    casting: json.casting,
    director: json.director,
    countries: json.countries,
    genres: json.genres,
    allocineId: json.allocine_id,
    allocineRating: json.allocine_rating,
    allocineNbRatings: json.allocine_nb_ratings,
    senscritiqueId: json.senscritique_id,
    senscritiqueRating: json.senscritique_rating,
    senscritiqueNbRatings: json.senscritique_nb_ratings,
    mubiId: json.mubi_id,
    mubiRating: json.mubi_rating,
    mubiNbRatings: json.mubi_nb_ratings,
    imdbId: json.imdb_id,
    imdbRating: json.imdb_rating,
    imdbNbRatings: json.imdb_nb_ratings,
    consolidatedRating: json.consolidated_rating,
    totalNbRatings: Math.floor(json.total_nb_ratings || 0),
    forChildren: json.for_children,
    trailerYoutubeId: json.trailer_youtube_id,
    trailerThumbnailPath: json.trailer_thumbnail_path,
    urlTitle: stringToUrlTitle(json.title_vf),
    urlPath: `/film/${stringToUrlTitle(json.title_vf)}-${json.id}`,
  }
}

export function apiToMovieWithShowtimes(json: any): MovieWithShowtimes {
  return {
    movie: apiToMovie(json.movie),
    theaters: json.theaters.map(apiToTheater),
  }
}

export type OrderBy =
  | 'consolidated_rating'
  | 'title_vf'
  | 'release_date'
  | 'duration'
  | 'next_showtime'
export type OrderDir = 'asc' | 'desc'

export interface SearchParams {
  latitude?: number
  longitude?: number
  range?: number
  start?: dayjs.Dayjs | dayjs.Dayjs[]
  end?: dayjs.Dayjs | dayjs.Dayjs[]
  cards?: string[]
  langs?: string[]
  audioLangs?: string[]
  releasedBefore?: string
  releasedAfter?: string
  movieId?: number | string
  movieName?: string
  theaterIds?: number[]
  forChildren?: boolean
  genres?: string[]
  excludeGenres?: string[]
  minDuration?: number
  maxDuration?: number
  orderBy?: OrderBy
  orderDir?: OrderDir
}

export interface DateTimeParams {
  day?: string
  startHour?: number
  endHour?: number
}

export interface DurationParams {
  minDuration?: number
  maxDuration?: number
}

export interface Place {
  address: string
  id: string | null
}

export type ReleaseMood = 'recent' | 'old' | undefined
export const DEFAULT_DATE_TEXT = 'Tous les jours'
