import { Checkbox, Stack, Title } from '@mantine/core'
import useSearchStore from 'states/useSearchStore'

export default function MoodSelect() {
  const releaseMood = useSearchStore((state) => state.releaseMood)
  const updateReleaseMood = useSearchStore((state) => state.updateReleaseMood)
  const ageFilter = useSearchStore((state) => state.ageFilter)
  const updateAgeFilter = useSearchStore((state) => state.updateAgeFilter)

  return (
    <Stack>
      <Title order={5}>Type de film</Title>
      <Checkbox
        label="Récent (<1 an)"
        checked={releaseMood === 'recent'}
        onChange={() => updateReleaseMood('recent')}
        color="zinc.6"
        size="md"
      />
      <Checkbox
        label="Ressortie (>1 an)"
        checked={releaseMood === 'old'}
        onChange={() => updateReleaseMood('old')}
        color="zinc.6"
        size="md"
      />
      <Checkbox
        label="Pour enfants"
        checked={ageFilter === 'children'}
        onChange={() =>
          updateAgeFilter(ageFilter === 'children' ? 'all' : 'children')
        }
        color="zinc.6"
        size="md"
      />
    </Stack>
  )
}
