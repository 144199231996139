import { Box, Grid } from '@mantine/core'
import 'dayjs/locale/fr'
import { useIsMobile } from 'providers/isMobileProvider'
import InfiniteScroll from 'react-infinite-scroll-component'

import Loader from 'components/common/Loader'
import type { MovieWithShowtimes } from 'types/showtimes'

import NoMoreResults from './NoMoreResults'
import OrderSelector from './OrderSelector'
import MoviePoster from './movieposter/MoviePoster'

type Props = {
  loadNextPage: () => void
  movies: MovieWithShowtimes[]
  hasMore: boolean
}

function Results({ movies, loadNextPage, hasMore }: Props) {
  const { isMobile } = useIsMobile()

  return (
    <Box className="max-w-5xl mx-auto px-[6px]">
      <OrderSelector />
      <InfiniteScroll
        dataLength={movies.length}
        next={loadNextPage}
        hasMore={hasMore}
        loader={<Loader />}
        endMessage={<NoMoreResults hasResults={movies.length > 0} />}
        className="overflow-hidden"
      >
        <Grid gutter={isMobile ? 8 : 'xs'}>
          {movies.map((resp: MovieWithShowtimes) => (
            <Grid.Col
              key={resp.movie.id}
              span={{ xl: 3, lg: 3, md: 3, sm: 3, xs: 4, base: 6 }}
              className={isMobile ? 'mt-0' : 'mt-2'}
            >
              <MoviePoster movie={resp.movie} theaters={resp.theaters} />
            </Grid.Col>
          ))}
        </Grid>
      </InfiniteScroll>
    </Box>
  )
}

export default Results
