import { Spoiler, Text } from '@mantine/core'
import { memo } from 'react'
import { ChevronDown, ChevronUp } from 'tabler-icons-react'

function ShowtimeEventBanner({
  eventInfo,
  isAvantPremiere,
}: {
  eventInfo: string | null
  isAvantPremiere: boolean
}) {
  const color = eventInfo ? 'bg-event' : 'bg-avp'
  return eventInfo || isAvantPremiere ? (
    <div className="flex-1">
      <Spoiler
        maxHeight={50}
        hideLabel={<ChevronUp size={24} className="text-zinc-900 mx-auto" />}
        showLabel={<ChevronDown size={24} className="mx-auto text-zinc-900" />}
        classNames={{
          control: 'w-full justify-center',
          root: `rounded-lg shadow-sm ${color} p-3 flex items-center text-black`,
          content: 'w-full',
        }}
      >
        <div className="flex-1 text-center">
          <Text className="my-auto font-semibold">
            {eventInfo || 'Avant-première'}
          </Text>
        </div>
      </Spoiler>
    </div>
  ) : null
}

export default memo(ShowtimeEventBanner)
